<template>

  <div class="tieboss-atvs">

    <hero :content="content.heroSection"/>

    <youtube-video :content="content.youtubeSection" bgColor="rgba(155,155,155,.37)"/>

    <pros
        :content="content.prosSection"
        titleColor="#0a3954"
        bkColor="rgba(245,166,35,.2)"
    />

    <insurances :content="content.insurancesSection"/>

    <div class="addToCart-wrapper">

      <add-cart-with-images :content="content.addToCartSection"/>

    </div>

    <f-a-q :content="content.faqSection" titleColor="rgb(204, 224, 245)"/>

    <div class="grid-container">
      <img :src="content.singleImage1" alt="img" class="fullscreen-image">
    </div>

    <made-in-section :content="content.madeInSection"/>

    <div class="grid-container">

      <div class="image-wrapper">

        <img :src="content.singleImage2" alt="img" class="small-image">

      </div>

    </div>

    <reviews :content="content.customerReviewsSection"/>

    <div class="grid-container">

      <h1 class="single-heading">{{ content.singleHeading }}</h1>

    </div>

    <div class="grid-container">

      <div class="boss-section" :style="{ backgroundImage: 'url(' + content.bossSection.imgSrc + ')' }">

        <div class="heading-wrapper">

          <h1>{{ content.bossSection.title }}</h1>

        </div>

      </div>

    </div>

    <other-products class="other-products" :content="content.otherProducts"/>

  </div>

</template>

<script>

import {ref} from "vue";
import Hero from "@/components/TieBoss/ATVs/HeroSection";
import YoutubeVideo from "@/components/YoutubeVideo";
import Pros from "@/components/TieBoss/ProsSection";
import Insurances from "@/components/TieBoss/ATVs/Insurances";
import AddCartWithImages from "@/components/TieBoss/ATVs/AddCartWithImages";
import FAQ from "@/components/TieBoss/FAQ";
import MadeInSection from "@/components/TieBoss/ATVs/MadeInSection";
import Reviews from "@/components/Reviews";
import OtherProducts from "@/components/TieBoss/OtherProducts";

export default {

  name: 'TieBossAtvsHome',

  components: {
    Hero,
    YoutubeVideo,
    Pros,
    Insurances,
    AddCartWithImages,
    FAQ,
    MadeInSection,
    Reviews,
    OtherProducts,
  },

  setup() {

    const content = ref();

    content.value = {

      heroSection: {

        backgroundImage: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/tiebossatvreleasemin-1-1632408925827.jpg?v=1632408925',

        logo: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-d147a4fd--NObacktiebossremovebgpreview_155x.png?v=1629632201',

        title: 'Less Time Tying and More Time Riding',

      },

      youtubeSection: {

        title: 'WATCH THE TIE BOSS IN ACTION - QUICK & EASY!!',

        ytSrc: 'https://www.youtube.com/embed/x2OLG3SgUiE',

      },

      prosSection: {

        title: 'BETTER THAN A BUNGEE, EASIER THAN A RATCHET',

        leftImage: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-8a4cb6c2--TieBossnew540x_400x.gif?v=1625432120',

        pros: [
          'Easy One Hand Lock and Release',
          'No Knots, no bungee cords and no ratchets.',
          'Maintains tension without tying off.',
          'No Moving Parts to Break',
        ],

        redText: 'NO MORE BUYING CHEAP BUNGEES & RATCHETS EVERY YEAR!!',

        rightImage: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-5670aada--FellingLifetimeWarrantylogo_496x.png?v=1625644879',

      },

      insurancesSection: {

        title: 'FAST, EASY, SECURE',

        insurances: [

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-12343f99--4379583_500x.png?v=1629791593',
            title: 'Easy to Use',
            description: 'Easy One Hand Lock and Release',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-b2ff5611--knots-icon_500x.png?v=1629630395',
            title: 'NO KNOTS',
            description: 'No need to have a knot tying merit badge to tie down effectively',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-5611493a--new-100_500x.jpg?v=1629630618',
            title: '30 Day Money Back',
            description: 'Buy with confidence.',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-493a0513--51510974transparentusaflagblackandwhitepnginfographic_500x.png?v=1629631026',
            title: 'MADE IN USA',
            description: 'Tie Boss is manufactured and shipped from Ohio.',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-051394a3--fastshippingglyphicondeliverytruckvector19992586-Edited_500x.jpg?v=1629631204',
            title: 'Next Day Shipping',
            description: 'Tie Boss products shipped in 1 business day',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-11493a05--95956023nakedarmorlimitedlifetimewarrantylogo_500x.png?v=1629630712',
            title: 'Limited Lifetime Warranty',
            description: 'We stand by our product for Life',
          },


        ],

        leftImg: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-82f09edb--tiebossprofd-2_337x.jpg?v=1630252280',

      },

      addToCartSection: {

        leftContent: {

          carouselImages: [
              'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-93d343fa--09A0167tieboss300lbloadf01r2removebgpreview.png?v=1630934065',
              'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/tieboss29-1-1632057102802.jpg?v=1632057111',
              'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/tieboss48-1-1632057102781.jpg?v=1632057105',
              'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/tieboss491-1-1632057102774.jpg?v=1632057102',
              'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/tieboss24-1-1632057140582.jpg?v=1632057140',
              'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/tieboss56-1-1632057102792.jpg?v=1632057108'
          ],

          singleImg: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-09814893--IMG0767removebgpreview_433x.png?v=1630933471',

        },

        rightContent: {

          title: 'Tie Boss HEAVY DUTY Set',

          subtitle: '3/8 inch - Rated for 300 lbs each',

          originalPrice: '$26.99',

          offers: [
            '1 FOR $22.99',
            '2 FOR $45.98',
          ],

          additionalInfo: '(1 Per Package, 2 Used For Most Applications)',

          packContentTitle: 'IN THE BOX',

          packageContent: [
            '1 - 3/8 Inch Tie Boss',
            '15 Feet of Solid Braid Polypropylene Rope',
            '1  - S Hook',
            '1  - G Hook',
          ],

          productImg: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-a79c374c--3THREEEIGHTHSTIEBOSS1x11removebgpreview_500x.png?v=1629941753',

          productColors: [
            'High Visibility Green',
            'Safety Orange',
            'Blue',
          ],

          submitButtonLabel: 'Add To Cart',

          shippingInfo: 'SHIPS FROM OHIO IN 1 BUSINESS DAY',

          certificationsImg: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-7747d3aa--PngItem3298955_337x.png?v=1625403776',

        }

      },

      faqSection: {

        title: 'Tie Boss FAQ',

        faqs: [

          {
            heading: 'How soon do they ship after ordering?',

            additionalInfo: [
              'We typically ship orders placed before 12 PM EST the same business day. All other orders ship in 1 business day.'
            ],

            open: false,
          },

          {
            heading: 'What does the Lifetime Warranty cover and how do I get it?',

            additionalInfo: [
              'Orders placed directly through our site come with a lifetime warranty by default.',
              'The warranty covers breakage of the Tie Boss in the unlikely event they break during normal use.',
              'We will replace your Tie Boss for the life of the product. Simply contact us with a photo of the broken part and your original order number and we will get it taken care of.',
              'Warranty covers everything except for the polypropylene rope. ',
            ],

            open: false,
          },

          {
            heading: 'Do you really offer a 100% Satisfaction Guarantee?',

            additionalInfo: [
              'Yes.',
              'We realize buying things sight unseen can be a challenge.',
              "If you receive your Tie Boss and decide it isn't for you, contact us back within 30 days of your order, you pay for shipping and we will provide a full refund for any product that is in new, unused condition."
            ],

            open: false,
          },

        ]

      },

      singleImage1: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-cf9472e3--Bereadyforanythingalways1024x1024_337x.jpg?v=1625470156',

      madeInSection: {

        imgSrc: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-5670aada--FellingLifetimeWarrantylogo_235x.png?v=1625644879',

        title: 'PROUDLY MADE IN THE USA',

        subtitle: 'Tie Boss products are manufactured in Piqua, Ohio.',

        description: "We're so confident in our team's work that we offer a 100% satisfaction guarantee and a lifetime warranty on critical components in the entire Tie Boss product line.",

      },

      singleImage2: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-b0d815fc--NOback-tie-boss_204x.jpg?v=1625341641',

      customerReviewsSection: {

        title: 'TESTIMONIALS',

        reviews: [

          {
            image: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-3024ee06--medATVTiedown500_337x.jpg?v=1603273119',
            description: '"We have had Tie Boss straps for less than a year but love the ease of use. For some reason my wife struggles with ratchet tie downs but uses these with ease. We mostly use them for our ATV\'s and kayaks but have also used them for hauling things from the hardware store. What I like most is the ability to essentially tie into itself, allowing more torque for better holding power. I highly recommend to anyone considering purchasing!"',
            reviewer: 'Trent',
            rating: 5,
          },

          {
            image: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-cb430259--medplanetiedown1500_337x.jpg?v=1603193706',
            description: '"I just wanted to let you know what a great product Tie Boss tie downs are. I\'ve tried almost every type of ratchet and cinch strap on the market, and found that the Tie Boss are far, far superior. They are so easy to use and once they are tightened and connected they stay that way. I swear by and love these tie downs and would highly recommend them to anyone and everyone! Thank you for such an awesome product and keep up the great work!"',
            reviewer: 'Marshall',
            rating: 5,
          },

          {
            image: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-525f6a4a--medsecurefurniture500_337x.jpg?v=1602598197',
            description: '"I have had several bungees break over the years, usually while pulling away from myself. This time was different- and dangerous. While kneeling and pulling upwards on a hooked rubber bungee it broke. My hands had the bungee but the unleashed tension in the strap propelled hands and the metal bungee hook right up into my eye at 60 mph. I was not wearing any safety glasses as EVERYONE MUST when using bungees."',
            reviewer: 'Johnny',
            rating: 5,
          },

        ],

      },

      singleHeading: 'WE HAVE A BOSS FOR EVERY JOB!',

      bossSection: {

        title: 'A Boss for Every Job',

        imgSrc: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-eba3a932--7015098815083992626356106397075533640761344n.jpg?v=1625581088',

      },

      otherProducts: [

        {

          title: 'Roof Rack Tie Downs',

          link: 'https://tieboss.com/pages/kayak-hanger-sling2',

          bgImg: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-4fe0766c--tiedowntiebossoutdooradventuresMediumWeightTieDownOutdoorSports.jpg?v=1625437930',

        },

        {

          title: 'HUNTING & FISHING',

          link: 'https://tieboss.com/pages/outdoor-adventures#huntfish',

          bgImg: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-3930e2ba--HUNTINGFISHING.jpg?v=1625430806',

        },

        {

          title: 'THE ULTIMATE CHAINSAW TOOL!',

          link: 'https://tieboss.com/collections/all-products/products/jaw-boss',

          bgImg: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-a05e8963--jbchainsawguy.jpg?v=1603270387',

        },

      ],

    }

    return {
      content,
    }

  }

}

</script>

<style lang="scss">

.tieboss-atvs {
  position: relative;
  color: #263e4d;
  font-size: 1.6rem;
  line-height: 1.3;

  h1, h2, h3, h4, h5, h6 {
    color: #0a3954;
    margin: 0 0 20px;
  }

  ul {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    list-style: disc;
    line-height: 1.6em;
    padding-left: 20px;

    li {
      margin-bottom: 10px;
    }

  }

  .addToCart-wrapper {
    max-width: 100%;
    width: 100%;
    background-color: rgba(74,144,226,.19);
    padding: 30px 0;
  }

  .fullscreen-image {
    max-width: 100%;
    width: 100%;
    padding: 15px;
    margin-top: 30px;
  }

  .image-wrapper {
    display: flex;
    align-items: center;

    .small-image {
      max-width: 100%;
      width: 300px;
      padding: 15px;
      margin: 0 auto;
    }

  }

  .single-heading {
    margin-top: 20px;
    font-size: 1.5em;
    text-align: center;
  }

  .boss-section {
    width: 100%;
    min-height: 200px;
    padding: 0 40px 20px;
    background-size: cover;
    background-position: center bottom;
    display: flex;
    align-items: flex-end;

    .heading-wrapper {
      width: 100%;
      font-size: 30px;
      margin-top: 0;
      background-color: rgba(245,166,35,.68);

      h1 {
        color: white;
        text-align: center;
        width: 200px;
        margin: 0 auto;
      }

    }

  }

  .other-products {
    margin-top: 30px;
  }

}

@media only screen and (max-width: 425px) {

  .tieboss-atvs {

    .single-heading {
      display: none;
    }

  }

}

</style>