<template>

  <div class="FAQ-section-wrapper">

    <div class="grid-container">

      <div class="FAQ">

        <div class="title">{{ content.title }}</div>

        <div
            v-for="(block, index) in content.QandA"
            :key="index"
            class="QandA">

          <div class="question">{{ block.question }}</div>
          <div class="answer">{{ block.answer }}</div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'FAQ',

  props: ['content'],

}

</script>

<style lang="scss" scoped>

.FAQ-section-wrapper {
  padding: 0 15px;

  .FAQ {
    line-height: 1.3em;
    font-size: 1.3em;

    .title {
      margin-bottom: 10px;
    }

    .QandA {
      margin-bottom: 20px;

      .question {
        font-weight: 700;
        margin-bottom: 15px;
      }

    }

  }

}

</style>