<template>

  <div :class=" open ? 'accordion-item open' : 'accordion-item' ">

    <div
        @click="$emit('toggleOpen', index)"
        class="heading">

      <i v-if="open" class="fas fa-minus"/>

      <i v-else class="fas fa-plus"/>

      <p>{{ content.heading }}</p>

    </div>

    <div class="additional-info">

      <p
          v-for="(paragraph, index) in content.additionalInfo"
          :key="index"
      >
        {{ paragraph }}
      </p>

    </div>


  </div>

</template>

<script>

export default {

  name: 'AccordionItem',

  props: ['content', 'index', 'open'],

}

</script>

<style lang="scss" scoped>

.accordion-item {
  display: block;
  width: 100%;
  max-width: 100%;
  margin: 15px auto;
  color: white;

  &.open .heading {
    margin-bottom: 15px;
  }

  .heading {
    position: relative;
    transition: all 0.4s linear;
    font-weight: 700;
    border-bottom: 1px solid #ebebeb;
    padding: 15px 15px 3px;
    background: #9b9b9b;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    cursor: pointer;

    i {
      margin-right: 10px;
      color: black;
      font-size: 0.7em;
    }

  }

  .additional-info {
    font-size: 1em;
    padding: 15px 15px 3px;
    opacity: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.5s ease-in-out;

    p {
      line-height: 1.2;
      margin-bottom: 25px;
    }

  }

  &.open .additional-info {
    opacity: 1;
    max-height: 1000px;
  }

}

@media only screen and (max-width: 425px) {

  .accordion-item {

    .heading {
      font-size: 1em;
      line-height: 1.2;
    }

    .additional-info {
      font-size: 0.8em;
    }

  }

}

</style>