<template>

  <div class="hero-section" :style="{ backgroundImage: 'url(' + bgImage + ')'}">

    <div class="grid-container">

      <h1>{{ content.heading }}</h1>

    </div>

  </div>

</template>

<script>

import {ref} from "vue";

export default {

  name: 'Hero',

  props: ['content'],

  setup(props) {

    const bgImage = ref();

    if (document.documentElement.clientWidth < 425) {
      bgImage.value = props.content.backgroundImageMobile;
    }
    else {
      bgImage.value = props.content.backgroundImage;
    }

    return {
      bgImage,
    }

  }

}

</script>

<style lang="scss" scoped>

.hero-section {
  position: relative;
  background-attachment: local;
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  min-height: 300px;

  .grid-container {
    position: relative;
    min-height: inherit;
  }

  h1 {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 3em;
    font-weight: 400;
    padding: 15px;
  }
}

@media only screen and (max-width: 425px) {

  .hero-section {
    h1 {
      font-size: 2em;
    }
  }

}

</style>