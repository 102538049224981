<template>
  <div class="counter-wrapper">

    <div :class="{minus: true, disabled: counterValue <= 1}" @click="decrementCounter">
      <i class="fas fa-minus"/>
    </div>

    <input
        type="number"
        v-model="counterValue"
        @input="inputChangeHandler()"
    >

    <div class="plus" @click="incrementCounter">
      <i class="fas fa-plus"/>
    </div>

  </div>
</template>

<script>

import {ref} from "vue";

export default {

  name: 'Counter',

  setup(props, {emit}) {

    const counterValue = ref(1);

    const decrementCounter = () => {
      if (counterValue.value > 1) {
        counterValue.value--;
        emit('counterChange', counterValue.value);
      }
    };

    const incrementCounter = () => {
      counterValue.value++;
      emit('counterChange', counterValue.value);
    };

    const inputChangeHandler = () => {

      if(!counterValue.value) {
        counterValue.value = 1;
      }

      emit('counterChange', counterValue.value);

    };

    return {
      counterValue,
      decrementCounter,
      incrementCounter,
      inputChangeHandler,
    }
  }

}

</script>

<style lang="scss" scoped>

.counter-wrapper {
  display: flex;
  align-items: center;

  .minus, .plus {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: #5d6b82;
    cursor: pointer;
    
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
    }

  }

  .minus.disabled {
    opacity: 0.2;
    cursor: default;
  }

  input[type=number] {
    margin: 0 10px;
    text-align: center;
    border: 1px solid #d1d5db;
    border-radius: 5px;
    width: 50px;
    height: 50px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

}

</style>