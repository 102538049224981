<template>

  <div class="made-in-section">

    <div class="grid-container">

      <div class="content">

        <img :src="content.imgSrc" alt="lifetime">

        <h1>{{ content.title }}</h1>

        <p class="subtitle"> {{ content.subtitle }} </p>

        <p class="description"> {{ content.description }} </p>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'MadeIn',

  props: ['content'],

}

</script>

<style lang="scss" scoped>

.made-in-section {

  .content {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    h1, p {
      margin: 15px;
    }

    h1 {
      font-size: 1.5em;
      font-weight: 700;
    }

  }

}

@media only screen and (max-width: 425px) {

  .made-in-section {
    .content {
      h1 {
        font-size: 1em;
      }
    }
  }

}

</style>