import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vue3StarRatings from "vue3-star-ratings";

const app = createApp(App);

app.use(router);

app.mount('#app');

app.component("vue3-star-ratings", vue3StarRatings);
