<template>

  <div id="pros" class="pros-section" :style="{ backgroundColor: bkColor }">

    <div class="grid-container">

      <div class="content">

        <h1 :style="{ color: titleColor}">{{ content.title }}</h1>
        <p v-if="content.subtitle" class="subtitle">{{ content.subtitle }}</p>
        <p v-if="content.description" class="description">{{ content.description }}</p>

        <div class="inner-section">

          <div class="left-side">
            <img :src="content.leftImage" alt="img">
          </div>

          <div class="right-side">

            <ul>
              <li
                  v-for="(pro, index) in content.pros"
                  :key="index"
              >
                {{ pro }}
              </li>
            </ul>

            <p v-if="content.redText" class="redText">
              {{ content.redText }}
            </p>

            <img
                :class="{'small': content.redText}"
                :src="content.rightImage"
                alt="img">

          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'Pros',

  props: ['content', 'titleColor', 'bkColor'],

}

</script>

<style lang="scss" scoped>

.pros-section{

  .content {
    text-align: center;

    h1 {
      font-size: 1.6em;
    }

    .subtitle, .description {
      font-weight: 700;
    }

    .description {
      font-size: 1.4em;
      width: 200px;
      margin: 0 auto;
    }

    .inner-section {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      .left-side {
        width: 50%;
        flex-grow: 1;

        img {
          max-width: 100%;
          width: 100%;
        }

      }

      .right-side {
        width: 50%;
        text-align: left;
        padding-left: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        flex-grow: 1;

        .redText {
          color: rgb(230, 0, 0);
          font-weight: bold;
          text-align: center;
          margin-top: 30px;
          margin-bottom: 15px;
        }

        img {
          max-width: 100%;
          margin-bottom: 50px;
        }

        img.small {
          max-width: 200px;
        }

      }

    }

  }

}

@media only screen and (max-width: 426px) {

  .pros-section{

    .content {

      h1 {
        font-size: 1.5em;
      }

      .subtitle {
        font-size: 0.7em;
      }

      .description {
        font-size: 1.2em;
        width: 200px;
        margin: 0 auto;
      }

      .inner-section {
        flex-direction: column;

        .left-side {
          width: 100%;
        }

        .right-side {
          width: 100%;
          padding-left: 0;

          img {
            margin-top: 25px;
          }

        }

      }

    }

  }

}

</style>