<template>

  <div class="separator" :style="{ backgroundColor: color}"/>

</template>

<script>

export default {

  name: 'ScSeparator',

  props: ['color'],

}

</script>

<style lang="scss" scoped>

.separator {
  width: 100%;
  height: 2px;
  margin: 16px 0;
}

</style>