<template>

  <div class="tieboss-kayaks">

    <div class="banner" @click="toggleBannerModal" v-if="isBannerVisible">
      <p>
        {{ content.bannerSection.bannerCaption }}
      </p>
    </div>

    <hero :content="content.heroSection"/>

    <choice
        @scrollToSection="scrollToSection"
        :content="content.choiceSection"/>

    <pros
        :content="content.prosSection"
        titleColor="#888888"
        bkColor="#ecf1d8"
    />

    <youtube-video :content="content.youtubeSection1"/>

    <insurances :content="content.insurancesSection"/>

    <promotion class="promotion-section" :content="content.promotionSection"/>

    <add-cart-with-images :content="content.addToCartSection"/>

    <div class="grid-container">

      <div id="hoisting">

        <h1 class="hoisting-title">{{ content.hoistingSection.title }}</h1>

        <img :src="content.hoistingSection.imgSrc" alt="img" class="fullscreen-image">

      </div>

    </div>

    <advantages :content="content.advantagesSection"/>

    <youtube-video :content="content.youtubeSection2"/>

    <div class="cart-section-wrapper">

      <add-cart-with-images
          :content="content.addToCartSection2"
          :type="2"
      />

    </div>

    <f-a-q :content="content.faqSection"/>

    <div class="grid-container">

      <div class="image-wrapper">

        <img :src="content.singleImage" alt="img" class="small-image">

      </div>

    </div>

    <reviews :content="content.customerReviewsSection"/>

    <div class="grid-container">

      <div class="boss-section" :style="{ backgroundImage: 'url(' + content.bossSection.imgSrc + ')' }">

        <div class="heading-wrapper">

          <h1>{{ content.bossSection.title }}</h1>

        </div>

      </div>

    </div>

    <other-products class="other-products" :content="content.otherProducts"/>

  </div>

  <transition name="fade">
    <banner-modal v-if="isOpened" :content="content.bannerSection.bannerModal" @closeModal="(isBannerVisible = true) && (isOpened = false)">

    </banner-modal>
  </transition>

</template>

<script>

import {ref} from "vue";
import Hero from "@/components/TieBoss/Kayaks/HeroSection";
import Choice from "@/components/TieBoss/Kayaks/ChoiceSection";
import Pros from "@/components/TieBoss/ProsSection";
import YoutubeVideo from "@/components/YoutubeVideo";
import Insurances from "@/components/TieBoss/Kayaks/Insurances";
import Promotion from "@/components/TieBoss/Kayaks/Promotion";
import AddCartWithImages from "@/components/TieBoss/Kayaks/AddCartWithImages";
import Advantages from "@/components/TieBoss/Kayaks/Advantages";
import FAQ from "@/components/TieBoss/FAQ";
import Reviews from "@/components/Reviews";
import OtherProducts from "@/components/TieBoss/OtherProducts";
import {scrollToSmoothly} from "@/utils/helpers";
import BannerModal from "@/components/TieBoss/Kayaks/BannerModal";

export default {

  name: 'TieBossKayaksHome',

  components: {
    BannerModal,
    Hero,
    Choice,
    Pros,
    YoutubeVideo,
    Insurances,
    Promotion,
    AddCartWithImages,
    Advantages,
    FAQ,
    Reviews,
    OtherProducts,
  },

  setup() {

    const content = ref();

    const isBannerVisible = ref(true);

    const isOpened = ref(false);

    content.value = {

      heroSection: {

        backgroundImage: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-cfd073da--12799235635581022175593166997079865780848941n.jpg?v=1629232838',

        logo: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-d147a4fd--NObacktiebossremovebgpreview_155x.png?v=1629632201',

        title: 'THE OFFICIAL TIE BOSS',

        description: 'The worry free Tie Down and Store Up System for your Water Craft!',

      },

      choiceSection: {

        title: 'CHOOSE ONE OR BOTH!',

        firstButtonLabel: 'Tying Down',

        secondButtonLabel: 'Hoisting UP',

      },

      prosSection: {

        title: 'TYING THINGS DOWN JUST GOT EASIER!',

        subtitle: '(Kayaks, Canoes, Paddleboards...)',

        description: 'Fast, Easy\n' + 'SECURE!!',

        leftImage: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-4fe0766c--tiedowntiebossoutdooradventuresMediumWeightTieDownOutdoorSports_337x.jpg?v=1625437930',

        pros: [
          'Accommodates Any Roof Rack or Trailer',
          'No Knots, No Bungee Cords, and No Damaging Ratchets',
          'Maintains Tension Without Tying Off',
          'Easy One Hand Lock and Release',
        ],

        rightImage: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-8a4cb6c2--TieBossnew540x_337x.gif?v=1625432120',

      },

      youtubeSection1: {

        title: 'WATCH THE TIE BOSS IN ACTION',

        ytSrc: 'https://www.youtube.com/embed/x2OLG3SgUiE',

      },

      insurancesSection: {

        title: 'BUY WITH CONFIDENCE',

        insurances: [
          'No Moving Parts to Break or Rust',
          '30 Day Customer Satisfaction Guarantee',
          'Made in the USA',
          'LIMITED LIFETIME WARRANTY'
        ],

        imgSrc: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-acfb52d0--7015098815083992626356106397075533640761344n_317x.jpg?v=1625428171',

      },

      promotionSection: {
        title: 'BETTER THAN A BUNGEE EASIER THAN A RATCHET',
        redText: 'NO MORE BUYING CHEAP BUNGEE CORDS YEAR AFTER YEAR!!',
        thinText: 'THE TIE BOSS COMES WITH A LIMITED',
        boldText: 'LIFETIME WARRANTY'
      },

      addToCartSection: {

        leftImages: [
          'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-b25d131b--14-inch-tie-boss-blue_337x.jpg?v=1626170603',
          'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-b6f15f0b--1436Image-010_337x.png?v=1625770148',
        ],

        rightContent: {

          title: 'TIE BOSS 1/4 INCH MEDIUM DUTY',

          subtitle: '(Rated for 150 lbs. Each)',

          originalPrice: '$19.99',

          offers: [
            '1 FOR $15.99',
            '2 FOR $31.98',
          ],

          packContentTitle: 'IN THE BOX',

          packageContent: [
            '1 - 1/4 Inch Medium Wt. Tie Boss ',
            '12 feet of solid braid, polypropylene rope',
            '2 durable hooks',
          ],

          additionalInfo: '(1 Per Package, 2 Used For Most Applications)',

          productImg: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-a79c374c--3THREEEIGHTHSTIEBOSS1x11removebgpreview_189x.png?v=1629941753',

          productColors: [
            'Safety Orange',
            'Blue',
            'High Visibility Green',
          ],

          submitButtonLabel: 'Add To Cart',

          shippingInfo: 'SHIPS FROM OHIO IN 1 BUSINESS DAY',

          certificationsImg: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-7747d3aa--PngItem3298955_337x.png?v=1625403776',

        }

      },

      hoistingSection: {

        title: 'HOISTING THINGS UP!',

        imgSrc: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-bfcbaff5--medstoreKayak500_337x.jpg?v=1597762373',

      },

      advantagesSection: {

        advantages: [
          'No Complex Mounting, just 2 Eye Hooks',
          'One person operation',
          'No Moving Parts to Break! Comes with a Lifetime Warranty',
          'Adjustable Sling fits Just about Any long board water craft',
        ],

        products: [
          'Kayaks, Surfboards',
          'Canoes',
          'Paddleboards',
        ],

        imgSrc: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-9ae6a06b--tieboss1_337x.jpg?v=1625339057'

      },

      youtubeSection2: {

        title: 'GETTING THINGS UP HAS NEVER BEEN EASIER',

        subtitle: "Our Sling has 9' of webbing on each end and 9' of webbing in the middle making it adjustable to fit all your storage your needs",

        ytSrc: 'https://www.youtube.com/embed/x2OLG3SgUiE',

      },

      addToCartSection2: {

        leftImages: [
          'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-972a852a--KAYAK9542lifestylimagesmin720x_337x.png?v=1625357681',
          'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-9ae6a06b--tieboss1_500x.jpg?v=1625339057',
          'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-e6a06b89--tiebossprofd_500x.jpg?v=1625340906'
        ],

        rightContent: {

          title: 'KAYAK HANGER SLING',

          characteristics: [
            'Max Load 150 lbs  Each (300 lbs. Total) ',
            'Easy to Install.  Only 2 Eye Hooks',
            'Raise, Lower and Store with no Tying',
            'One Person Operation',
            'Made in the USA',
          ],

          additionalInfo: '100% satisfaction guarantee and a lifetime warranty on critical components',

          productImg: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-f45acc5c--81tLIViStLACSL1500_337x.jpg?v=1625400745',

          originalPrice: '105.99',

          price: '$89.99',

          packContentTitle: 'IN THE BOX',

          packageContent: [
            '2 - 1/4" Tie Boss Sets',
            'One Universal Sling',
          ],

          submitButtonLabel: 'Add To Cart',

          certificationsImg: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-7747d3aa--PngItem3298955_337x.png?v=1625403776',

        }

      },

      faqSection: {

        title: 'Tie Boss FAQ',

        faqs: [

          {
            heading: 'How soon do they ship after ordering?',

            additionalInfo: [
              'We typically ship orders placed before 12 PM EST the same business day. All other orders ship in 1 business day.'
            ],

            open: false,
          },

          {
            heading: 'What does the Lifetime Warranty cover and how do I get it?',

            additionalInfo: [
              'Orders placed directly through our site come with a lifetime warranty by default.',
              'The warranty covers breakage of the Tie Boss in the unlikely event they break during normal use.',
              'We will replace your Tie Boss for the life of the product. Simply contact us with a photo of the broken part and your original order number and we will get it taken care of.',
              'Warranty covers everything except for the polypropylene rope. ',
            ],

            open: false,
          },

          {
            heading: 'Do you really offer a 100% Satisfaction Guarantee?',

            additionalInfo: [
              'Yes.',
              'We realize buying things sight unseen can be a challenge.',
              "If you receive your Tie Boss and decide it isn't for you, contact us back within 30 days of your order, you pay for shipping and we will provide a full refund for any product that is in new, unused condition."
            ],

            open: false,
          },

        ]

      },

      singleImage: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-b0d815fc--NOback-tie-boss_204x.jpg?v=1625341641',

      customerReviewsSection: {

        title: 'TESTIMONIALS',

        reviews: [

          {
            image: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-091b0434--medboatstorage500_337x.jpg?v=1597762316',
            description: '"I use Tie Boss to hang my kayaks up in the garage... I recently bought a new kayak that weighs 100 lbs, the Tie Boss hanger sling with a pulley worked so great for lifting it up, I have since ordered more for my other kayaks. Love Tie Boss!! Works great!"',
            reviewer: 'Brad',
            rating: 5,
          },

          {
            image: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-50e9bfcb--medRCPlane500_337x.jpg?v=1597762366',
            description: '"Tie Boss is an excellent product you do not find at Hardware Stores, ideal for Hobbyists, installation is a breeze...fool proof, easy to use and operates flawlessly. My plane is hanging from the roof in my garage, and I can confidently leave my cars below."',
            reviewer: 'Luis',
            rating: 5,
          },

          {
            image: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-bfcbaff5--medstoreKayak500_337x.jpg?v=1597762373',
            description: '"We are so happy to have our kayak stored safely, securely and out of the way of our vehicles in our garage. Now we have peace of mind that our kayak will be safe from storms and the damaging effects of the sun when not in use. We highly recommend the Tie Boss Kayak storage system!"',
            reviewer: 'Capt. Baobby',
            rating: 5,
          },

        ],

      },

      bossSection: {

        title: 'A Boss for Every Job',

        imgSrc: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-eba3a932--7015098815083992626356106397075533640761344n.jpg?v=1625581088',

      },

      otherProducts: [

        {

          title: 'VEHICLE TIE DOWNS',

          link: 'https://tieboss.com/pages/atv2',

          bgImg: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-51aaced9--tiebossatvreleasemin.jpg?v=1625472544',

        },

        {

          title: 'HUNTING AND FISHING',

          link: 'https://tieboss.com/pages/outdoor-adventures#huntfish',

          bgImg: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-3930e2ba--HUNTINGFISHING.jpg?v=1625430806',

        },

        {

          title: 'THE ULTIMATE CHAINSAW TOOL',

          link: 'https://tieboss.com/collections/all-products/products/jaw-boss',

          bgImg: 'https://cdn.shopify.com/s/files/1/0299/5329/4429/t/3/assets/pf-05ffa05e--jb2.jpg?v=1603270374',

        },

      ],

      bannerSection: {
        bannerCaption: 'Get 15% Off',
        bannerModal: {
          title: 'Hang on a second!',
          info: 'Here\'s 15% off your first order! Ready to lose the hassle?',
          inputLabel: 'Enter email...',
          buttonLabel: 'Get 15% off'
        }
      }

    }

    const scrollToSection = (section) => {

      let chosenSection = null;

      if (section === 1) {
        chosenSection = document.getElementById('pros');
      } else {
        chosenSection = document.getElementById('hoisting');
      }

      scrollToSmoothly(chosenSection.offsetTop, 600);

    }

    const toggleBannerModal = () => {
      isOpened.value = true;
      isBannerVisible.value = false;
    }

    return {
      content,
      isBannerVisible,
      isOpened,
      scrollToSection,
      toggleBannerModal,
    }

  }

}

</script>

<style lang="scss">

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.tieboss-kayaks {
  position: relative;
  color: #263e4d;
  font-size: 1.6rem;
  line-height: 1.3;

  .banner {
    width: 200px;
    height: 100%;
    max-height: 50px;
    border-radius: 5px 5px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(34, 72, 140);
    cursor: pointer;
    position: fixed;
    bottom: 0;

    p {
      color: white;
      text-align: center;
      text-transform: uppercase;
    }

  }

  h1, h2, h3, h4, h5, h6 {
    color: #0a3954;
    margin: 0 0 20px;
  }

  ul {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    list-style: disc;
    line-height: 1.6em;
    padding-left: 20px;

    li {
      margin-bottom: 10px;
    }

  }

  .hoisting-title {
    color: rgb(136, 136, 136);
    font-size: 2em;
    text-align: center;
    font-weight: 400;
    margin-top: 50px;
  }

  .fullscreen-image {
    max-width: 100%;
    width: 100%;
    margin-top: 30px;
  }

  .image-wrapper {
    display: flex;
    align-items: center;

    .small-image {
      max-width: 100%;
      width: 300px;
      padding: 15px;
      margin: 0 auto;
    }

  }

  .cart-section-wrapper {
    background-color: #f5f5f5;
  }

  .boss-section {
    width: 100%;
    min-height: 200px;
    padding: 0 40px 20px;
    background-size: cover;
    background-position: center bottom;
    display: flex;
    align-items: flex-end;
    margin-top: 100px;

    .heading-wrapper {
      width: 100%;
      font-size: 30px;
      margin-top: 0;
      background-color: rgba(245, 166, 35, .68);

      h1 {
        color: white;
        text-align: center;
        width: 200px;
        margin: 0 auto;
      }

    }

  }

  .other-products {
    margin-top: 30px;
  }

  .promotion-section {
    display: none;
  }

}

@media only screen and (max-width: 426px) {

  .tieboss-kayaks {

    .hoisting-title {
      font-size: 1.3em;
      background-color: #eee;
      padding: 5px;
    }

    .fullscreen-image {
      margin-top: 0;
    }

    .promotion-section {
      display: block;
    }

    .banner {
      width: 90%;
      height: 100%;
      max-height: 50px;
      left: 50%;
      transform: translateX(-50%);
    }

  }

}

</style>