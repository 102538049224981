<template>

  <div class="yt-section-wrapper" :style="{ backgroundColor: bgColor }">

    <div class="grid-container">

      <h1 v-if="content.title">{{ content.title }}</h1>

      <p class="description" v-if="content.subtitle">{{ content.subtitle }}</p>

      <div class="yt-video">

        <iframe width="560" height="315" :src="content.ytSrc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

      </div>

      <sc-button
          v-if="content.ytButtonLabel"
          @click="ytButtonClickHandler"
          :label="content.ytButtonLabel"
          iconClass="fas fa-star"
      />

    </div>

  </div>

</template>

<script>

import ScButton from "@/components/ScButton";

export default {

  name: 'YoutubeVideo',

  components: {
    ScButton
  },

  props: ['content', 'bgColor'],

  setup() {

    const ytButtonClickHandler = () => {
      console.log('YT Button Click');
    };

    return {
      ytButtonClickHandler,
    }

  }

}

</script>

<style lang="scss" scoped>

.yt-section-wrapper {
  background-color: #f5f5f5;
  padding: 50px 0;
  text-align: center;

  h1 {
    font-size: 2em;
    font-weight: 400;
  }

  .description {
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 25px;
  }

  .yt-video {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0;
  }

}

@media only screen and (max-width: 425px) {

  .yt-section-wrapper {

    h1 {
      font-size: 1.2em;
      font-weight: 400;
    }

    .description {
      font-size: 0.8em;
    }

  }

}

</style>