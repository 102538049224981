<template>

  <div class="gallery-wrapper">

    <div class="grid-container">

      <div class="gallery">

        <h1>{{ content.title }}</h1>

        <div class="images-wrapper">

          <img
              v-for="(imgSrc, index) in content.images"
              :key="index"
              :src="imgSrc" alt="gallery-photo">

        </div>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'Gallery',

  props: ['content'],

}

</script>

<style lang="scss" scoped>

.gallery-wrapper {

  .gallery {
    text-align: center;
    font-size: 1.3em;

    .images-wrapper {
      display: grid;
      grid-template-columns: repeat(3,1fr);
      grid-auto-rows: minmax(100px,auto);
      column-gap: 20px;
      row-gap: 50px;
      max-width: 100%;
      width: 100%;

      img {
        max-width: 100%;
        align-self: start;
        justify-self: center;
      }

    }

  }

}

@media only screen and (max-width: 425px) {

  .gallery-wrapper {

    .gallery {

      .images-wrapper {
        grid-template-columns: repeat(1 ,1fr);

        img {
          width: 100%;
        }

      }

    }

  }

}

</style>