<template>

  <div class="choice-section">

    <div class="grid-container">

      <div class="content">

        <h1>{{ content.title }}</h1>

        <div class="buttons-wrapper">

          <div
              @click="scrollToSection(1)"
              class="button">
            <span>{{ content.firstButtonLabel }}</span>
          </div>

          <div
              @click="scrollToSection(2)"
              class="button">
            <span>{{ content.secondButtonLabel }}</span>
          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'Choice',

  props: ['content'],

  setup(props, {emit}) {

    const scrollToSection = (section) => {

      emit("scrollToSection", section);

    }

    return {
      scrollToSection,
    }

  }

}

</script>

<style lang="scss" scoped>

.choice-section {

  .content {

    h1 {
      font-size: 1.5em;
      text-align: center;
    }

    .buttons-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 25px;

      .button {
        background-color: #919da9;
        font-size: 1.2em;
        border-radius: 15px;
        color: #fff;
        padding: 12px 20px;
        cursor: pointer;
        line-height: 1.2em;
      }

    }

  }

}

@media only screen and (max-width: 426px) {

  .choice-section {

    .content {

      h1 {
        font-size: 1.4em;
      }

      .buttons-wrapper {

        .button {
          font-size: 1em;
          text-align: center;
        }

      }

    }

  }

}

</style>