<template>

  <div class="faq-section">

    <div class="grid-container">

      <div class="heading">

        <h1 :style="{ color: titleColor }">{{content.title}}</h1>

      </div>

      <div class="content">

        <accordion :content="content.faqs"/>

      </div>

    </div>

  </div>

</template>

<script>
import Accordion from "@/components/Accordion";

export default {

  name: 'FAQ',

  props: ['content', 'titleColor'],

  components: {
    Accordion,
  },

}

</script>

<style lang="scss" scoped>

.faq-section {

  .heading {
    width: 100%;
    padding: 20px;
    background-color: rgba(74,74,74,.65);
    margin-bottom: 20px;

    h1 {
      text-align: center;
      color: rgb(102, 163, 224);
      font-size: 1.8em;
      margin-bottom: 0;
    }

  }

  .content {
    width: 100%;
    background-color: rgba(74,74,74,.65);
    padding: 15px;
    color: white;
  }

}

@media only screen and (max-width: 426px) {

  .faq-section {

    .heading {

      h1 {
        font-size: 1.4em;
        line-height: 1.3;
      }

    }

  }

}

</style>