<template>

  <div class="importance-section">

    <div class="grid-container">

      <div class="content">

        <span class="title">{{ content.title }}</span>

        <h1>{{ content.subtitle }}</h1>

        <div class="main-content">

          <div class="left-content">

            <img :src="content.leftImg" :alt="content.leftImgAlt">

          </div>

          <div class="right-content">

            <ul>
              <li
                  v-for="(reason, index) in content.reasons"
                  :key="index"
              >
                <p> <span>{{ reason.title }}</span> - {{ reason.description }}</p>

              </li>
            </ul>

          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'Importance',

  props: ['content'],

}

</script>

<style lang="scss" scoped>

.importance-section {

  .content {
    text-align: center;

    .title {
      color: rgb(0, 71, 178);
    }

    h1 {
      margin-top: 15px;
      font-size: 1.3em;
    }

    .main-content {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 25px;
      text-align: left;
      margin-top: 50px;

      .left-content {
        width: 50%;

        img {
          max-width: 100%;
          width: 100%;
        }

      }

      .right-content {
        width: 50%;

        ul {

          li {
            margin-bottom: 50px;

            span {
              color: rgb(0, 71, 178);
            }

          }

        }

      }

    }

  }

}

@media only screen and (max-width: 1024px) {

  .importance-section {

    .content {

      .main-content {
        flex-direction: column;

        .left-content, .right-content {
          width: 100%;
        }

      }

    }

  }

}

@media only screen and (max-width: 425px) {

  .importance-section {

    .content {

      h1 {
        font-size: 1em;
      }

    }

  }

}

</style>