<template>

  <div class="reviews-section">

    <div class="grid-container">

      <h1>{{ content.title }}</h1>

      <div class="reviews-wrapper">

        <div
            v-for="(review, index) in content.reviews"
            :key="index"
            class="review">

          <img :src="review.image" alt="pic">

          <div class="info">

            <vue3-star-ratings
                :showControl="false"
                :disableClick="true"
                starColor="#B2B200"
                v-model="review.rating"
                v-if="review.rating"
            />

            <div class="title" v-if="review.title">
              {{ review.title }}
            </div>

            <p class="description">
              {{ review.description }}
            </p>

            <span class="reviewer" v-if="review.reviewer">
              {{ review.reviewer }}
            </span>
          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'Reviews',

  props: ['content'],

  components: {},

  setup() {

    return {}
  }


}

</script>

<style lang="scss" scoped>

.reviews-section {
  margin-top: 50px;

  h1 {
    text-transform: uppercase;
    font-size: 30px;
    color: #000;
    font-weight: 400;
    text-align: center;
  }

  .reviews-wrapper {
    margin: 5% auto 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
    align-items: flex-start;
    max-width: 100vw;

    .review {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 20px;

      .vue3-star-ratings__wrapper {
        margin: 0;
        padding: 0;
      }

      img {
        width: 100%;
      }

      .info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 15px;
        background-color: #f5f5f5;
        padding: 15px;
        min-width: 100%;

        .title {
          font-weight: 700;
        }

        .description {
          text-align: center;
          line-height: 1.3em;
          font-size: 0.8em;
        }

        .reviewer {
          text-align: center;
          font-size: 12px;
          color: #707070;
          font-style: italic;
        }

      }

    }

  }

}

@media only screen and (max-width: 425px) {

  .reviews-section {

    h1 {
      font-size: 25px;
      padding: 0 20px;
      text-align: center;
    }

    .reviews-wrapper {
      grid-template-columns: repeat(1, 1fr);
    }

  }

}


</style>