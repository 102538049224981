<template>

  <div class="other-products">

    <div
        v-for="(product, index) in content"
        :key="index"
        :style="{ backgroundImage: 'url(' + product.bgImg + ')' }"
        class="product">

      <div class="heading">

        <a :href="product.link">{{ product.title }}</a>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'OtherProducts',

  props: ['content'],

}

</script>

<style lang="scss" scoped>

.other-products {
  max-width: 60%;
  margin: 0 auto;

  .product {
    min-height: 650px;
    background-size: cover;
    background-position: 50% 0;
    margin-bottom: 30px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;


    .heading {
      width: 100%;
      text-align: center;
      background-color: rgba(0,0,0,.52);

      a {
        text-decoration: none;
        color: white;
        font-size: 1.3em;
        font-weight: 700;
      }

    }

  }

}

@media only screen and (max-width: 769px) {

  .other-products {
    max-width: 100%;

    .product {
      min-height: 350px;
    }

  }

}

</style>