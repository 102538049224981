<template>

  <div class="grid-container">

    <div v-if="content" class="addCart-wrapper">
      <div
          v-for="(productImg) in content.leftContent"
          :key="productImg.id"
          class="productImage-wrapper">
        <img
            v-if="activeProductIndex === productImg.id"
            :src="productImg.imgSrc" alt="product">
      </div>
      <add-to-cart :content="content.rightContent" @changedRadioOption="radioOptionChangeHandler($event)"/>
    </div>

  </div>

</template>

<script>

import AddToCart from "@/components/LollaLand/AddToCart";
import {ref} from "vue";

export default {

  name: 'AddCartWithImage',

  props: ['content'],

  components: {
    AddToCart,
  },

  setup() {

    const activeProductIndex = ref(0)

    const radioOptionChangeHandler = (index) => {
      console.log('Radio button index chosen, shown from AddCartWithImage: ');
      console.log(index);
      activeProductIndex.value = index;
    }

    return {
      activeProductIndex,
      radioOptionChangeHandler,
    }

  }

}

</script>

<style lang="scss" scoped>

.addCart-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 25px;

  .productImage-wrapper {
    max-width: 50%;

    img {
      max-width: 100%;
      width: 600px;
    }

  }

  .addToCart-section {
    max-width: 50%;
  }

}

@media only screen and (max-width: 425px) {

  .addCart-wrapper {
    align-items: center;
    flex-direction: column;

    .productImage-wrapper {
      max-width: 100%;
    }

    .addToCart-section {
      max-width: 100%;
    }

  }

}

</style>