<template>

  <div class="design-section">

    <div class="grid-container">

      <div class="content">

        <div class="left-content">

          <img :src="content.imgSrc" alt="designer">

        </div>

        <div class="right-content">

          <span class="title"> {{ content.title }} </span>

          <span class="subtitle"> {{ content.subtitle }} </span>

          <p
              v-for="(paragraph, index) in content.paragraphs"
              :key="index"
          >
            {{ paragraph }}
          </p>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'Design',

  props: ['content'],

}

</script>

<style lang="scss" scoped>

.design-section {

  .content {
    display: flex;

    .left-content, .right-content {
      width: 50%;
      flex-grow: 1;
    }

    .left-content {

      img {
        max-width: 100%;
        width: 100%;
      }

    }

    .right-content {
      display: flex;
      flex-direction: column;
      align-self: center;

      .title {
        font-size: 1.5em;
        color: #434343;
      }

      .subtitle {
        color: #b79365;
        margin: 20px 0;
      }

      p {
        font-size: 0.8em;
        color: #646464;
        margin-bottom: 20px;
      }

    }

  }

}

@media only screen and (max-width: 425px) {

  .design-section {

    .content {
      flex-direction: column;

      .left-content, .right-content {
        width: 100%;
        flex-grow: 1;
      }

    }

  }

}

</style>