import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home'
import LollaLandHome from "@/views/LollaLandHome";
import TieBossKayaksHome from "@/views/TieBossKayaksHome";
import TieBossAtvsHome from "@/views/TieBossAtvsHome";
import KizingoKids from "@/views/KizingoKids";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },

  {
    path: '/lollalandhome',
    name: 'LollaLandHome',
    component: LollaLandHome,
  },

  {
    path: '/tiebosskayakshome',
    name: 'TieBossKayaksHome',
    component: TieBossKayaksHome,
  },

  {
    path: '/tiebossatvshome',
    name: 'TieBossAtvsHome',
    component: TieBossAtvsHome,
  },

  {
    path: '/kizongokidshome',
    name: 'KizingoKids',
    component: KizingoKids,
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
