<template>

  <div class="home">

    <div class="carousel-wrapper">

      <carousel
          :items-to-show="6"
          controlBg="#000"

      >

        <slide v-for="(product, index) in products"
               :key="index"
               class="product-block">

            <img :src="product.imgSrc" alt="product">

            <span class="title">{{ product.title }}</span>

            <div class="rating">

              <vue3-star-ratings
                  :showControl="false"
                  :disableClick="true"
                  starSize="16"
                  starColor="#e7721b"
                  v-model="product.rating"
                  v-if="product.rating"
              />

              <span class="reviews">({{ product.reviews }})</span>

            </div>

            <span class="price">$ {{ product.price }}</span>


        </slide>

        <template #addons>

          <navigation/>

        </template>

      </carousel>

    </div>

  </div>

</template>

<script>
// @ is an alias to /src
// import Carousel from "@/components/Carousel";
// import Slide from "@/components/Slide";
import Reviews from "@/components/Reviews.vue";

import 'vue3-carousel/dist/carousel.css';
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel';


import Image1 from "@/assets/Kizing-curved-baby-and-toddler-spoon-supports-self-feeding.jpeg"
import Image2 from "@/assets/Kizingo-curved-toddler-spoons-make-feeding-easy.png"
import Image3 from "@/assets/Kizingo-toddler-spoons-for-baby-led-weaning.png"
import {ref} from "vue";

export default {

  name: 'Home',

  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Reviews,
  },

  setup() {

    const carouselSlides = [Image1, Image2, Image3];

    const products = ref();

    products.value = [
      {
        imgSrc: 'https://cdn-yotpo-images-production.yotpo.com/PromotedProduct/47053/217317215/thumb.png?1637958627',
        title: '5-Piece Toddler Utensil Set - NEW!',
        rating: 100,
        reviews: 9,
        price:' 15.00'
      },

      {
        imgSrc: 'https://cdn-yotpo-images-production.yotpo.com/PromotedProduct/47052/217317216/thumb.png?1637958627',
        title: '7-Bowl Set: Rainbow Assortment',
        rating: 100,
        reviews: 14,
        price: '42.00'
      },

      {
        imgSrc: 'https://cdn-yotpo-images-production.yotpo.com/PromotedProduct/47051/217317218/thumb.png?1637958629',
        title: '7-Dipping Cup Set: Rainbow...',
        rating: 100,
        reviews: 8,
        price: '28.00'
      },

      {
        imgSrc: 'https://cdn-yotpo-images-production.yotpo.com/PromotedProduct/47050/217298634/thumb.png?1637956189',
        title: 'Leak Proof Panda Spout Cup',
        rating: 100,
        reviews: 2,
        price: '12.00'
      },

      {
        imgSrc: 'https://cdn-yotpo-images-production.yotpo.com/Product/290486966/215926816/square.jpg?1644381616',
        title: 'Straw Replacement Pack for Lollacup',
        rating: 100,
        reviews: 2,
        price: '6.00'
      },

      {
        imgSrc: 'https://cdn-yotpo-images-production.yotpo.com/Product/290486967/215933372/square.jpg?1640289653',
        title: 'Plates (Sold Individually)',
        rating: 100,
        reviews: 1,
        price: '6.00'
      },

      {
        imgSrc: 'https://cdn-yotpo-images-production.yotpo.com/Product/290486965/215921623/square.jpg?1640186830',
        title: '"Cool Touch" Microwave...',
        rating: 0,
        reviews: 0,
        price: '6.00'
      },

      {
        imgSrc: 'https://cdn-yotpo-images-production.yotpo.com/Product/290486983/215931645/square.jpg?1644433216',
        title: '20% off a Straw Replacement Pack',
        rating: 0,
        reviews: 0,
        price: '4.00'
      },

    ]

    return {
      carouselSlides,
      products,
    }
  }

}
</script>

<style lang="scss">

.carousel__prev, .carousel__next {
  background-color: transparent;
}

.carousel__prev {
  transform: translate(-100%, -50%);
}

.carousel__next {
  transform: translate(100%, -50%);
}

.carousel__icon {
  fill: #6A6C77;
}

</style>

<style lang="scss" scoped>

.home {

  .product-section {
    display: flex;
    align-items: flex-start;
    gap: 25px;

    .carousel-wrapper {
      position: relative;
      height: 450px;
      width: 50%;
      padding-bottom: 30px;

      .carousel {
        position: relative;
        max-height: 100%;
        height: 100%;
        width: 100%;

        .slide-info {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          max-height: 100%;
          height: 100%;
          overflow: hidden;

          img {
            min-width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

      }

    }

    .product {
      width: 50%;
    }

  }

}

.home {

  .carousel-wrapper {
    width: 90%;
    height: 300px;
    margin: 0 auto;

    .carousel__slide {
      padding: 0 20px;
    }

    img {
      max-height: 300px;
      max-width: 100%;
    }

  }

  .product-block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #737373;
    overflow: hidden;

    .title {

    }

    .rating {
      display: flex;
      align-items: center;
      justify-content: center;

      .vue3-star-ratings__wrapper {
        margin: 0;
        padding: 10px;
      }

      .reviews {
        font-size: 14px;
      }

    }

    .price {
      margin-top: 3px;
      color: #2f84ed;
      font-weight: 700;
    }

  }

}

@media only screen and (max-width: 425px) {

  .home {

    .product-section {
      flex-direction: column;

      .carousel-wrapper {
        width: 100%;
      }

      .product {
        padding: 20px;
        width: 100%;
      }

    }

  }

}

</style>
