<template>

  <div class="addToCart-section">

    <p class="quote">{{ content.quote }}</p>

    <span class="title">{{ content.title }}</span>

    <span class="description">{{ content.description }}</span>

    <div class="rating">

      <vue3-star-ratings
          :showControl="false"
          :disableClick="true"
          starSize="16"
          v-model="content.ratingValue"
          @click="scrollToSection"
      />

      <span @click="scrollToSection"> {{ content.numberOfReviewers }} Reviewers</span>

    </div>

    <sc-separator color="#919da9"/>

    <p class="price">{{ content.price }}</p>

    <sc-separator color="#919da9"/>

    <span class="colors-title">CHOOSE YOUR COLOR</span>

    <div class="radioButtons-wrapper">

      <div
          v-for="(option, index) in content.radioButtonOptions"
          :key="index"
          class="option">
        <input
            type="radio"
            :id="option.label"
            v-model="chosenOption"
            :value="option.label"
            :checked="index === 0"
            @change="radioChangeHandler(option.id)"
        ><label :for="option.label"> {{ option.label }} </label>
      </div>

    </div>

    <sc-separator color="#919da9"/>

    <counter class="counter-wrapper" @counter-change="counterChangeHandler($event)"/>

    <span class="shippingInfo">{{ content.shippingInfo }}</span>

    <div class="add-button" @click="addToCartHandler">
      <i class="fas fa-shopping-cart"/>
      <span>{{ content.addToCartLabel }}</span>
    </div>

    <img :src="content.certifications" alt="certifications" class="certifications">

  </div>

</template>

<script>
import ScSeparator from "@/components/ScSeparator";
import Counter from "@/components/Counter";
import {ref} from "vue";
import {scrollToSmoothly} from "@/utils/helpers";

export default {

  name: 'AddToCart2',

  props: ['content'],

  components: {
    ScSeparator,
    Counter
  },

  setup(props, {emit}) {

    const counterValue = ref(1);

    const chosenOption = ref();

    chosenOption.value = props.content.radioButtonOptions[0].label;

    const radioChangeHandler = (index) => {
      emit("changedRadioOption", index);
    };

    const counterChangeHandler = (value) => {
      counterValue.value = value;
    }

    const addToCartHandler = () => {
      console.log('Chosen options: ' + chosenOption.value);
      console.log('Chosen quantity: ' + counterValue.value);
    }

    const scrollToSection = () => {

      let chosenSection = document.getElementById('reviews');

      scrollToSmoothly(chosenSection.offsetTop, 600);

    }

    return {
      chosenOption,
      radioChangeHandler,
      counterChangeHandler,
      addToCartHandler,
      scrollToSection,
    }

  }

}

</script>

<style lang="scss" scoped>

.addToCart-section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;

  .title, .description, .colors-title {
    color: #d0021b;
  }

  .quote {
    font-style: italic;
    margin-bottom: 20px;
  }

  .title {
    font-size: 2.2em;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .description {
    font-size: 1.8em;
    margin-bottom: 20px;
  }

  .rating {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;

    .vue3-star-ratings__wrapper {
      margin: 0;
      padding: 0;
    }

    span {
      margin-left: 5px;
      font-size: 13px;
      color: #2f84ed;
      text-decoration: none;
    }

  }

  .price {
    font-size: 1.5em;
  }

  .colors-title {
    font-size: 1.2em;
    font-weight: 700;
    margin: 20px 0;
  }

  .radioButtons-wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 5px;

    .option {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      input[type=radio] {
        cursor: pointer;
      }

      label {
        padding-left: 5px;
        line-height: 1.3em;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }

  .counter-wrapper {
    margin-bottom: 10px;
    align-self: center;
  }

  .shippingInfo {
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 10px;
    align-self: center;
  }

  .add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    background-color: #7ed321;
    padding: 10px 20px;
    margin-bottom: 30px;
    text-transform: uppercase;
    border-radius: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 1.7em;

    span {
      line-height: 1;
      font-weight: 700;
      text-align: center;
      letter-spacing: -1px;
      margin-left: 10px;
    }
  }

  .certifications {
    max-width: 100%;
    width: 100%;
  }

}

@media only screen and (max-width: 425px) {

  .addToCart-section {

    .radioButtons-wrapper {
      flex-direction: column;
    }

    .counter-wrapper {
      align-self: center;
    }

    .add-button {
      padding: 10px;
      font-size: 1.3em;
      background-color: #f5a623;
      border-radius: 25px;
      color: #000;
    }

  }

}

</style>