<template>

  <div class="bundle-section">

    <div class="grid-container">

      <div class="content">

        <span v-if="content.hasOwnProperty('firstText')" class="firstText">{{ content.firstText }}</span>

        <h1 v-if="content.hasOwnProperty('title')" class="title">{{ content.title }}</h1>

        <img v-if="content.hasOwnProperty('packageImgSrc')" :src="content.packageImgSrc" alt="">

        <sc-separator color="#919da9"/>

        <ul
            v-if="content.hasOwnProperty('listItems')"
            class="list-items"
            v-for="(listItem, index) in content.listItems"
            :key="index"
        >

          <li v-if="listItem.hasOwnProperty('text')">{{ listItem.text }}</li>

          <ul
              v-if="listItem.hasOwnProperty('sublistItems')"
              class="sublist-items"
          >
            <li
                v-for="(sublistItem, index) in listItem.sublistItems"
                :key="index"
            >{{ sublistItem }}</li>

          </ul>

        </ul>

        <h1 v-if="content.hasOwnProperty('boldRedText')" class="bold-text">{{ content.boldRedText }}</h1>

        <sc-separator color="#919da9"/>

        <span v-if="content.hasOwnProperty('priceLabel')" class="price-label">{{ content.priceLabel }}</span>

        <span v-if="content.hasOwnProperty('priceText')" class="price">{{ content.priceText }}</span>

        <div v-if="content.hasOwnProperty('radioButtonOptions')" class="radioButtons-wrapper">

          <div
              v-for="(option, index) in content.radioButtonOptions"
              :key="index"
              class="option">
            <input
                type="radio"
                v-model="chosenOption"
                :id="option"
                :value="option"
                :checked="index === 0"
            ><label :for="option"> {{ option }} </label>
          </div>

        </div>

        <counter class="counter-wrapper" @counter-change="counterChangeHandler($event)"/>

        <div v-if="content.hasOwnProperty('buttonLabel')" class="add-button" @click="addToCartHandler">
          <i class="fas fa-shopping-cart"/>
          <span>{{ content.buttonLabel }}</span>
        </div>

      </div>

    </div>

  </div>

</template>

<script>
import {ref} from "vue";
import ScSeparator from "@/components/ScSeparator";
import Counter from '@/components/Counter.vue';


export default {

  name: 'BundleAddToCart',

  components: {
    ScSeparator,
    Counter
  },

  props: ['content'],

  setup(props) {

    const counterValue = ref(1);

    const chosenOption = ref();

    chosenOption.value = props.content.radioButtonOptions[0];

    const counterChangeHandler = (value) => {
      counterValue.value = value;
    }

    const addToCartHandler = () => {
      console.log('Chosen options: ' + chosenOption.value);
      console.log('Chosen quantity: ' + counterValue.value);
    }

    return {
      chosenOption,
      counterChangeHandler,
      addToCartHandler,

    }

  },

}

</script>

<style lang="scss" scoped>

@media only screen and (max-width: 426px) {

  .bundle-section {
    padding: 25px;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .firstText {
        align-self: flex-start;
        font-size: 1.2em;
      }

      .title {
        font-size: 2em;
        text-align: center;
        color: #d0021b;
        margin: 25px 0;
      }

      img {
        max-width: 100%;
        width: 100%;
      }

      ul.list-items {
        list-style: disc;
        color: #d0021b;
        font-size: 1.3em;
        align-self: flex-start;
        padding-left: 20px;
        margin-bottom: 10px;
        li {
          margin-bottom: 10px;
        }

        ul.sublist-items {
          list-style: square;
          color: black;
          font-size: 0.8em;
          padding-left: 40px;

          li:last-child {
            margin-bottom: 0;
          }
        }

      }

      .bold-text {
        margin-top: 25px;
        font-size: 2em;
        font-weight: bold;
        text-align: center;
        color: #d0021b;
      }

      .price-label, .price {
        font-size: 1.3em;
      }

      .price {
        margin-bottom: 25px;
      }

      .radioButtons-wrapper {
        align-self: flex-start;

        .option {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          label {
            margin-left: 5px;
          }
        }
      }

      .add-button {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        background-color: #ef3340;
        padding: 10px;
        text-transform: uppercase;
        border-radius: 5px;
        cursor: pointer;
        color: #fff;
        margin: 25px 0;

        span {
          font-size: 1.3em;
          line-height: 1;
          font-weight: 700;
          text-align: center;
          letter-spacing: -1px;
          margin-left: 10px;
        }
      }

    }

  }

}

</style>