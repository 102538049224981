<template>

  <div class="grid-container">

    <div class="addCart-wrapper">

      <div class="productImages-wrapper">

        <div class="productImage-wrapper">

          <div class="carousel-wrapper">
            <Carousel
                class="carousel"
                v-slot="{ currentSlide }"
                :startAutoPlay="false"
                :navigation="true"
                :pagination="true"
                :slideCount="content.leftContent.carouselImages.length"
            >
              <Slide
                  v-for="(slide, index) in content.leftContent.carouselImages"
                  :key="index">
                <div
                    class="slide-info"
                    v-show="currentSlide === index + 1"
                >
                  <img :src="slide" alt="ceva">
                </div>
              </Slide>
            </Carousel>
          </div>

          <img :src="content.leftContent.singleImg" alt="product">

        </div>

      </div>

      <add-to-cart :content="content.rightContent"/>

    </div>

  </div>

</template>

<script>
import AddToCart from "@/components/TieBoss/ATVs/AddToCart";
import Carousel from "@/components/Carousel";
import Slide from "@/components/Slide";

export default {

  name: 'AddCartWithImages',

  props: {

    content: {
      type: Object,
      required: true,
    },

  },

  components: {
    AddToCart,
    Carousel,
    Slide,
  },

}

</script>

<style lang="scss" scoped>

.addCart-wrapper {
  display: flex;
  gap: 30px;

  .productImages-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    width: 50%;
    flex-grow: 1;
    gap: 15px;

    .productImage-wrapper {
      width: 100%;

      img {
        max-width: 100%;
        width: 100%;
      }

    }

  }

  .addToCart-section {
    max-width: 50%;
    flex-grow: 1;
  }

  .carousel-wrapper {
    position: relative;
    height: 450px;
    width: 100%;
    padding-bottom: 30px;

    .carousel {
      position: relative;
      max-height: 100%;
      height: 100%;
      width: 100%;

      .slide-info {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-height: 100%;
        height: 100%;
        overflow: hidden;

        img {
          min-width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

    }

  }

}

@media only screen and (max-width: 425px) {

  .addCart-wrapper {
    flex-direction: column;

    .productImages-wrapper {
      max-width: 100%;
      width: 100%;
    }

    .addToCart-section {
      max-width: 100%;
    }

  }

}

</style>