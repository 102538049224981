<template>

  <div class="recommendation-section" :style="{ backgroundImage: 'url(' + content.backgroundImage + ')' }">

    <div class="content">

      <span class="description">{{ content.description }}</span>

      <span class="site"> -  {{ content.site }}</span>

    </div>

  </div>

</template>

<script>

export default {

  name: 'Recommendation',

  props: ['content'],

}

</script>

<style lang="scss" scoped>

.recommendation-section {
  padding: 100px 0;
  margin-top: 50px;
  background-size: cover;
  background-position: 50% 50%;
  display: none;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    gap: 25px;
    padding-left: 30px;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #fff;

    .site {
      align-self: flex-end;
    }

  }

}

@media only screen and (max-width: 1024px) {

  .recommendation-section {
    display: block;

    .content {
      font-size: 21px;
    }

  }

}

@media only screen and (max-width: 425px) {

  .recommendation-section {
    padding: 50px 20px;

    .content {
      width: 80%;
    }

  }

}

</style>