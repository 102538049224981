<template>

  <hero :content="content.heroSection"/>

  <sub-hero :content="content.subheroSection"/>

  <product-info :content="content.productInfoSection"/>

  <div class="grid-container">

    <sc-separator color="#919da9"/>

  </div>

  <!--  <add-cart-with-image :content="content.addToCartSection"/>-->

  <add-cart-with-carousel class="addToCart" :content="content.addToCartSection2"/>

<!--  <div class="grid-container">-->
<!--    <img :src="content.singleImage1" alt="img" class="fullscreen-image">-->
<!--  </div>-->

<!--  <div class="grid-container">-->
<!--    <img :src="content.singleImage2" alt="img" class="small-image">-->
<!--  </div>-->

  <learn-to-drink class="learnToDrink" :content="content.learnToDrinkSection"/>

  <products-carousel :content="content.productsCarouselSection"/>

  <reviews class="reviews-section" id="reviews" :content="content.customerReviewsSection"/>

  <strategy :content="content.strategySection"/>

  <so-easy :content="content.soEasySection"/>

  <div class="grid-container">

    <h1 class="single-heading red">A CELEBRITY FAVOURITE</h1>

  </div>

<!--  <recommendation :content="content.recommendationSection"/>-->

  <div class="grid-container">
    <img :src="content.singleImage3" alt="img" class="fullscreen-image">
  </div>

  <div class="grid-container mobile-section">
    <img :src="content.singleImage4" alt="img" class="fullscreen-image">
  </div>

  <div class="grid-container mobile-section">
    <img :src="content.singleImage5" alt="img" class="fullscreen-image">
  </div>

  <add-cart-with-image class="mobile-section" :content="content.addToCartSection"/>

  <bundle-add-to-cart class="bundle-section" :content="content.bundleAddToCartSection"/>

  <div class="grid-container">

    <h1 class="single-heading">CUSTOMER REVIEWS</h1>

  </div>

  <div class="grid-container">

    <div class="feature-section">

      <img :src="content.featuredSection.imgSrc"
           alt="features">

    </div>

  </div>

<!--  <add-cart-with-image :content="content.addToCartSection"/>-->

  <gallery :content="content.gallerySection"/>

<!--  <div class="grid-container">-->
<!--    <sc-button-->
<!--        @click="chooseColorButtonClickHandler"-->
<!--        :label="content.chooseColorButtonLabel"-->
<!--        iconClass="fas fa-star"-->
<!--    />-->
<!--  </div>-->

  <youtube-video :content="content.youtubeSection"/>

  <reviews :content="content.reviewsSection"/>

  <div class="grid-container">
    <sc-button
        :label="content.getCupButtonLabel"
        iconClass="fas fa-star"
    />
  </div>

  <f-a-q :content="content.faqSection"/>

  <div class="grid-container">
    <sc-button
        @click="faqButtonClickHandler"
        :label="content.FAQButtonLabel"
    />
  </div>

</template>

<script>

import Hero from "@/components/LollaLand/Hero.vue";
import SubHero from "@/components/LollaLand/SubHero";
import ProductInfo from "@/components/LollaLand/ProductInfo";
import ScSeparator from "@/components/ScSeparator";
import AddCartWithImage from "@/components/LollaLand/AddCartWithImage";
import LearnToDrink from "@/components/LollaLand/LearnToDrink";
import SoEasy from "@/components/LollaLand/SoEasy";
import Recommendation from "@/components/LollaLand/Recommendation";
import Reviews from "@/components/Reviews.vue";
import FAQ from "@/components/LollaLand/FAQ";
import ScButton from "@/components/ScButton";
import YoutubeVideo from "@/components/YoutubeVideo";
import Gallery from "@/components/Gallery";
import AddCartWithCarousel from "@/components/LollaLand/AddCartWithCarousel";
import Strategy from "@/components/LollaLand/Strategy";
import ProductsCarousel from "@/components/ProductsCarousel";
import BundleAddToCart from "@/components/LollaLand/BundleAddToCart";

import {ref} from "vue";
import backgroundImage from "@/assets/pf-07e61568--carolinetranlollaland090-copy-1.jpeg";
import foregroundImage from "@/assets/shopify-home-page-template-pagefly-fabulous-001.png";
import imgSrc from "@/assets/pf-719678a8--asseenonstheader_337x.png";

export default {

  name: 'LollaLandHome',

  components: {
    Hero,
    SubHero,
    ProductInfo,
    ScSeparator,
    AddCartWithImage,
    LearnToDrink,
    SoEasy,
    Recommendation,
    Reviews,
    FAQ,
    ScButton,
    YoutubeVideo,
    Gallery,
    AddCartWithCarousel,
    Strategy,
    ProductsCarousel,
    BundleAddToCart,
  },

  setup() {

    const content = ref();

    content.value = {

      heroSection: {

        backgroundImage: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-dc777868--lollacup096.jpg?v=1623970984',

        backgroundImageMobile: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-03aa7454--TrMicHHg.jpeg?v=1612615313.jpg?v=1623970984',

        heading: 'THE OFFICIAL LOLLACUP',

      },

      subheroSection: {

        title: 'FUNDED BY MARK CUBAN AND ROBERT HERJAVEC',

        imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-9307cae6--pf6bd77c8bAsseenonSharkTank_337x.png?v=1623971384',

      },

      productInfoSection: {

        leftContent: {

          desktopImage: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-4f8b28f5--544472426063776131691361735636032155458328n_500x.jpg?v=1605931178',

          tabletImage: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-83acc4df--6504745920881324448137906905120514935619584o_500x.jpg?v=1604142160',

          mobileImage: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-92f3b6f4--Childs-new-favorite-Cup_337x.jpg?v=1605815464',

        },

        rightContent: [
          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-68752cba--download-1_60x.png?v=1623140626',
            heading: 'MAKES STRAW DRINKING EASY AND FRUSTRATION FREE',
            subHeading: 'No Valve and big soft straw allows MAXIMUM drinkage with LITTLE suckage!!',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-752cbaa0--Good-to-go_60x.png?v=1623140642',
            heading: 'FROM BOTTLE TO STRAW AS EARLY AS 6 MONTHS',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-baa00491--dishwashersafeontoprack260nw573710383-1-Edited?v=1623140670',
            heading: 'TOP RACK DISHWASHER SAFE',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-04918fe5--redirected150nw288770318-1-Edited?v=1623140705',
            heading: 'BPA FREE, LEAD FREE, PHTHALATE FREE',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-918fe5f5--643Mapstatesunitedusa512-1_51x.png?v=1623140725',
            heading: 'MADE IN THE USA',
            subHeading: 'We Proudly mold, Assemble and Ship the Lollacup from California.',
          },

        ]
      },

      addToCartSection2: {

        leftContent: [
          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/Holdinglollacup.jpg?v=1605700629',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/CorrectedPink.png?v=1602674139'
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_pink_02inpackagingforWEB.jpg?v=1605700661'
          },

          {

            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_blue_2forWEB.jpg?v=1605700661',
            id: 4,
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_blue_02inpackagingforWEB.jpg?v=1605700661'
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_red_2forWEB.jpg?v=1605700661'
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_red_02inpackagingforWEB.jpg?v=1605700661'
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_green_2forWEB.jpg?v=1605700661',
            id: 2,
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_green_02inpackagingforWEB.jpg?v=1605700661'
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_orange_2forWEB.jpg?v=1605700661',
            id: 3,
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_orange_02inpackagingforWEB.jpg?v=1605700661'
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_black_2forWEB.jpg?v=1605700661',
            id: 6,
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_black_02inpackagingforWEB.jpg?v=1605700661'
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_turquoise_2forWEB.jpg?v=1605700661',
            id: 7,
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_turquoise_02inpackagingforWEB.jpg?v=1605700661'
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_measurement_markings2forWEB.jpg?v=1605700661'
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_bottom_markings2forWEB.jpg?v=1605700661'
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/Lollacup-weighted-strawforWEB.jpg?v=1605700661'
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup-in-Bugaboo-stroller-cup-holderforWEB.jpg?v=1605700661'
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup-baby-shower-giftforWEB.jpg?v=1605700661'
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/Lollacup-Straw-Replacement-Pack-forWEB_2edabd05-327e-4d82-ace8-4cf4c35fa56a.jpg?v=1611919552'
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_pink_2forWEB.jpg?v=1605700661',
            id: 5,
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/Lola_Cup_and_Brush.png?v=1611919552',
            id: 1,
          },

        ],

        rightContent: {

          quote: '"Speech pathologist mama here, and my 7 month old loves this cup" -Kaite R.',

          title: 'THE LOLLACUP',

          description: 'Weighted Straw Sippy Cup',

          ratingValue: 4.5,

          numberOfReviewers: 95,

          price: '$16 + FREE SHIPPING',

          radioButtonOptions: [
            {
              id: 1,
              label: 'Bold Red',
            },
            {
              id: 2,
              label: 'Good Green',
            },
            {
              id: 3,
              label: 'Happy Orange',
            },
            {
              id: 4,
              label: 'Brave Blue',
            },
            {
              id: 5,
              label: 'Posh Pink'
            },
            {
              id: 6,
              label: 'Chic Black - Backordered',
            },
            {
              id: 7,
              label: 'Cool Turquoise'
            },

          ],

          shippingInfo: 'Ships Next Business Day from California',

          addToCartLabel: 'ADD TO CART',

          certifications: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-cdd3a43e--Leggo_337x.png?v=1622545986'

        }

      },

      // singleImage1: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-ec0520ad--safeimage-9_337x.jpg?v=1604705700',
      //
      // singleImage2: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-6522fd56--jULIANA-ii_337x.png?v=1615721920',

      learnToDrinkSection: {

        heading: 'WHY IS LEARNING TO DRINK FROM A STRAW SO IMPORTANT?',

        subHeading: 'Oral Musculature Development',

        additionalInfo: '(or the muscles your child uses to talk!)',

        paragraphs: [
          'Drinking from a straw will help your child develop these critical muscles.',
          'Our cup is one of a few weighted straw cups on the market without a valve.  This allows your child to start drinking from a straw as early as 6 months.',
          'THE EARLIER THE BETTER!',
        ],

        imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-d079b1d6--LollacupLifestyle02-Edited?v=1624092668'

      },

      productsCarouselSection: {

        title: 'Lollaland Best Sellers',

        products: [
          {
            imgSrc: 'https://cdn-yotpo-images-production.yotpo.com/PromotedProduct/47053/217317215/thumb.png?1637958627',
            title: '5-Piece Toddler Utensil Set - NEW!',
            rating: 4.5,
            reviews: 9,
            price:' 15.00',
            link: 'https://yotpo.com/go/xaV0RK0G'
          },

          {
            imgSrc: 'https://cdn-yotpo-images-production.yotpo.com/PromotedProduct/47052/217317216/thumb.png?1637958627',
            title: '7-Bowl Set: Rainbow Assortment',
            rating: 5,
            reviews: 14,
            price: '42.00',
            link: 'https://www.lollaland.com/products/7-bowls?ref=yotpo_94'
          },

          {
            imgSrc: 'https://cdn-yotpo-images-production.yotpo.com/PromotedProduct/47051/217317218/thumb.png?1637958629',
            title: '7-Dipping Cup Set: Rainbow...',
            rating: 5,
            reviews: 8,
            price: '28.00',
            link: 'https://www.lollaland.com/products/7-dipping?ref=yotpo_94',
          },

          {
            imgSrc: 'https://cdn-yotpo-images-production.yotpo.com/PromotedProduct/47050/217298634/thumb.png?1637956189',
            title: 'Leak Proof Panda Spout Cup',
            rating: 5,
            reviews: 2,
            price: '12.00',
            link: 'https://www.lollaland.com/products/lollaland-panda-bear-9-ounce-hard-spout-sippy-cup?ref=yotpo_94'
          },

          {
            imgSrc: 'https://cdn-yotpo-images-production.yotpo.com/Product/290486966/215926816/square.jpg?1644381616',
            title: 'Straw Replacement Pack for Lollacup',
            rating: 5,
            reviews: 2,
            price: '6.00',
            link: 'https://www.lollaland.com/products/straw-replacement-pack?ref=yotpo_94'
          },

          {
            imgSrc: 'https://cdn-yotpo-images-production.yotpo.com/Product/290486967/215933372/square.jpg?1640289653',
            title: 'Plates (Sold Individually)',
            rating: 5,
            reviews: 0,
            price: '6.00',
            link: 'https://www.lollaland.com/products/mealtime-set-plates?ref=yotpo_94'
          },

          {
            imgSrc: 'https://cdn-yotpo-images-production.yotpo.com/Product/290486972/215939489/square.jpg?1640279742',
            title: 'Multipurpose Swedish Sponge...',
            rating: 0,
            reviews: 0,
            price: '18.00',
            link: 'https://www.lollaland.com/products/multipurpose-sponge-cloths?ref=yotpo_94'
          },

          {
            imgSrc: 'https://cdn-yotpo-images-production.yotpo.com/Product/290486973/215933857/square.jpg?1640186400',
            title: 'Complete Mealtime Bundle',
            rating: 0,
            reviews: 0,
            price: '45.00',
            link: 'https://www.lollaland.com/products/mealtime-bundle?ref=yotpo_94'
          },

        ],

      },

      customerReviewsSection: {

        title: 'CUSTOMER REVIEWS',

        reviews: [

          {
            image: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-d520fe87--image1350826166413579_337x.jpg?v=1622542239',
            description: 'I have 3 for my 15 mo old. Best cup we have found yet. And he loves carrying it around.',
            reviewer: 'Trisha',
            rating: 5,
          },

          {
            image: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-20fe879a--182636215101589874394627071093965303701002757n_337x.jpg?v=1622542342',
            description: 'Hannah loves her lolllacup! We have 3 of them! So far, no losses while cruising around on the golf cart!!!',
            reviewer: 'Donna',
            rating: 5,
          },

          {
            image: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-fe879aae--171287974101583491367898958282093235893965790n_337x.jpg?v=1622542396',
            description: 'Wanted to introduce my baby to straw cups before 9 months. Looked at some different options and went with the lollacup. She took to it immediately and loves it.',
            reviewer: 'Melissa',
            rating: 5,
          },

          {
            image: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-879aaea7--182085053102254419238241014357864927826772595n_337x.jpg?v=1622542576',
            title: 'LOVE, just LOVE!',
            description: 'First day with the cup. Love ❤️',
            reviewer: 'Jennifer',
          },

          {
            image: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-9aaea743--Medealollacup_337x.jpg?v=1622542811',
            description: 'Have to make a comment on this cup... very pleased with it and ordering a couple more for my home daycare. The big handles are the best',
            reviewer: 'Joan',
            rating: 5,
          },

          {
            image: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-aea74326--wyattwblack_337x.JPG?v=1622542837',
            description: 'The best!!! Love this cup!! My son started using it at 7 months and it has been a game changer! He could not use the sippy cup or the munchkin weighted straw cup with ease. This was super easy for him to pick up on! Love it!',
            reviewer: 'Brittney',
            rating: 5,
          },

        ],

      },

      strategySection: {

        title: 'STRATEGY FOR EARLY LEARNERS',

        subtitle: 'NO VALVE ALLOWS FOR ASSISTED DRINKING',

        leftImg: 'https://i.makeagif.com/media/11-09-2021/JGPq9M.gif',

        paragraphs: [
            'Our cup is produced with a special blend of plastic that allows it to be durable yet soft enough to squeeze.',
            'Similar to the Honey Bear cup, for those little ones that have trouble getting started, just give a gentle push on the "P", and it will send water slowly up the straw.',
            'Place the straw in your little one\'s mouth and they will receive the water with just the smallest suck. ',
            'This then leads to purposeful sucking.'
        ]

      },

      soEasySection: {

        heading: 'SO EASY',

        description: '<span data-pf-type="Text" class="sc-jHkVzv dyVEVs pf-139_">Our straw cup has fewer pieces than almost all other sippy straw cups on the market. <br> Fewer pieces mean:<br> <br>&nbsp;-Easy to Assemble,&nbsp;&nbsp;<br>&nbsp;-Easy to Clean <br>&nbsp;-Fewer Parts to lose!! <br><br>\n' +
            '\n' +
            'Straw Cleaning Brush is Included with each cup!</span>',

        gifSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-47784995--Animated-GIFdownsizedlarge_337x.gif?v=1628514374'

      },

      featuredSection: {

        imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-e6ad4f0c--featured_337x.png?v=1612617828',

        label: 'GET OUR PREMIUM BUNDLE PACK!!',

      },

      singleImage3: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-e6156875--1025677910167473016852403533461431616766297o-1_337x.jpg?v=1623139673',

      singleImage4: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-c7781901--Final-Boobie_337x.png?v=1605438751',

      singleImage5: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-696d96bd--6504745920881324448137906905120514935619584o_337x.jpg?v=1603890267',

      addToCartSection: {

        leftContent: [

          {
            id: 0,
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/Lola_Cup_and_Brush.png?v=1611919552'
          },

          {
            id: 1,
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_green_2forWEB.jpg?v=1605700661'
          },

          {
            id: 2,
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_orange_2forWEB.jpg?v=1605700661'
          },

          {
            id: 3,
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_blue_2forWEB.jpg?v=1605700661'
          },

          {
            id: 4,
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_pink_2forWEB.jpg?v=1605700661'
          },

          {
            id: 5,
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_black_2forWEB.jpg?v=1605700661'
          },

          {
            id: 6,
            imgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/lollacup_turquoise_2forWEB.jpg?v=1605700661'
          },

        ],

        rightContent: {

          title: 'THE OFFICIAL LOLLACUP',

          subTitle: 'Weighted Straw Sippy Cup',

          freeShipping: '$16 + FREE SHIPPING',

          radioButtonOptions: [
            'Bold Red',
            'Good Green',
            'Happy Orange',
            'Brave Blue',
            'Posh Pink',
            'Chic Black - Backordered',
            'Cool Turquoise',
          ],

          addToCartLabel: 'ADD TO CART',

          // paymentBanner: 'https://cdn.shopify.com/s/files/1/2170/1117/t/10/assets/pf-3fe62f35-e6c1-4624-850f-f24bfaaa3b19--PCProduct-08_500x.png?313333',

          shippingInfo: 'Ships same or next business day from California',

          certifications: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-cdd3a43e--Leggo_337x.png?v=1622545986'
        },

      },

      FAQButtonLabel: 'GET MY LOLLACUP NOW',

      getCupButtonLabel: 'GET MY LOLLACUP NOW',

      recommendationSection: {

        description: 'Martha Stewart calls Lollacups "so simple, so cute, so functional," and celebrities such as Sandra Bullock and Jay-Z have been spotted carrying them for their children.',

        site: 'PARENTING.COM',

        backgroundImage: 'https://cdn.shopify.com/s/files/1/2170/1117/t/9/assets/shopify-home-page-template-pagefly-fabulous-005.png?6041',

      },

      gallerySection: [
        'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-c409cafb--RobKardashianMakesRareAppearanceKUWTKCelebrateDreamBirthday_175x.jpg?v=1604745713',
        'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-cafb77fe--safeimage-18_175x.jpg?v=1604745730',
        'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-77fe288a--safeimage-16_175x.jpg?v=1604745746',
        'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-288a59cc--safeimage-17_175x.jpg?v=1604745763',
        'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-09cafb77--safeimage-15_175x.jpg?v=1604745722',
        'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-fb77fe28--safeimage-12_175x.jpg?v=1604745737',
        'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-fe288a59--safeimage-13_175x.jpg?v=1604745754',
        'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-59cc55c3--safeimage-5_175x.jpg?v=1604745820',
        'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-eb57b453--safeimage-7_175x.jpg?v=1604705623',
        'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-cc55c304--safeimage-21_175x.jpg?v=1604746023',
      ],

      youtubeSection: {

        title: 'Watch Us on The View!',

        ytSrc: 'https://www.youtube.com/embed/47jVnSLtfHk',

        ytButtonLabel: 'Pick My Color',

      },

      reviewsSection: {

        title: 'REVIEWS!',

        reviews: [

          {
            image: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-400a92c9--ScreenShot20160926at94252AMmin_337x.png?v=1602925599',
            title: 'VeggiesandVirtue.com',
            description: "Out of all the sippy/straw children's cups we own, the Lollacup is by far our favorite as well as our daughter's. It made our daughter's initial transition from bottle to straw cup at 9 months as seamless as I could imagine. It continues to be the most used straw kid's cup we own.",
            reviewer: 'Ashley',
          },

          {
            image: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-86400a92--110430656761198858311403483896661332974433nmin_337x.jpg?v=1602925592',
            title: 'The Pinning Mama',
            description: "At about $16 a cup you’re paying about twice as much as the average sippy cup, but there is so much thought that went into the design that it is worth it. My water bottle was $14 so why not spend close to the same amount on my kids if I’m going to get a quality product? In the past, I have spent so much money on “leak-proof” cups that just don’t work. I’d rather have a cup that works and won’t ruin the inside of my bags when liquid comes out!",
            reviewer: 'Kimber',
          },

          {
            image: 'https://cdn.shopify.com/s/files/1/0168/5252/t/25/assets/pf-8086400a--18342686101587455436007141182455305836559248nmin_337x.jpg?v=1602925578',
            title: 'Thats It LA',
            description: "The chick design is ultra-cute and appeals to my 3.5 year old, who also loves to use the cup. Based on their experiences, this definitely looks like a cup that the kids will enjoy for a long time (I better pick up an extra for my daughter!).",
          },

        ],

      },

      faqSection: {

        title: 'FAQ',

        QandA: [
          {
            question: 'Is the Lollacup spill-proof?',
            answer: 'Lollacup straw sippy cups are valve-free, so they are NOT spill-proof. Although having a spill-proof cup with active toddlers is sometimes convenient, our aim at lollacup is to eliminate unnecessary challenges for our children. We’ve found that spill-proof straw and sippy cups can be incredibly difficult to drink from, causing frustration for many children. Our goal, in creating lollacup, was to make drinking from a straw easy for young children.'
          },

          {
            question: 'Is the Lollacup dishwasher-safe?',
            answer: 'Absolutely, yes. As busy parents, dishwasher is king, but hand-washing your lollacup is always recommended. Plastics have gotten a bad reputation in recent years as leaching chemicals such as BPA and phthalates, especially when exposed to the extreme temperatures in microwaves and dishwashers. In addition, at high temperatures chemical reactions can occur, invisible stress can happen, and plastics can degrade, especially when harsh cleaning agents are added to the mix. Rest-assured that lollacups are BPA-free and phthalate-free, but we advocate hand-washing plastic products whenever possible.'
          },

          {
            question: 'How long do the straws last?',
            answer: 'Lollacup straws can, unfortunately, stain (usually from pasta sauce or dark foods/drinks). We had the straw material specially formulated to be soft/pliable, durable, as well as free of BPA, PVC, latex, and phthalates. The drawback is that it can stain. We are constantly asking our chemist about new, safe alternatives, but for the time being, rather than using more additives in the material, we sell straw replacement packs on our website and in some of our retail stores if you\'d like to periodically replace your straw.'
          },

          {
            question: 'Is the Lollacup recyclable?',
            answer: 'Yes, all parts of the Lollacup are recyclable.'
          },

          {
            question: 'Why does it matter that the Lollacup is made in the USA?',
            answer: 'So much of what we use on a daily basis is mass-produced in a foreign country, mainly for cost reasons. We wanted our customers to have the peace of mind that comes with knowing that lollacups are made from safe plastics, sourced within the USA, in a facility that has its Device Manufacturing License from the State of California, Department of Health Services, Food and Drug Branch and is ISO 9001:2000 certified. Also, we try to do whatever we can to support our local economy.'
          },
        ],

      },

      bundleAddToCartSection: {

        firstText: 'GET OUR PREMIUM BUNDLE PACK!!',

        title: 'Complete Mealtime Bundle',

        packageImgSrc: 'https://cdn.shopify.com/s/files/1/0168/5252/products/Lollaland-Mealtime-Bundle_PINK.jpg?v=1617743325',

        listItems: [
          {
            text: '1 Lollacup Straw Sippy ($16 Value)',
          },
          {
            text: '1 Toddler Utensil Set That includes:',
            sublistItems: ['2 Spoons'
              ,'2 Forks'
              ,'1 Hygienic Travel/Storage pouch'
              ,'($15 value)']
          },
          {
            text: '1 Mealtime Set that includes:',
            sublistItems: ['1 Toddler Bowl'
              ,'1 Toddler Plate'
              ,'1 Dipping Cup'
              ,'($20 Value)']
          },
        ],

        boldRedText: 'TOTAL SHIPPED SEPARATELY $51',

        priceLabel: 'BUNDLE PRICING',

        priceText: '$45+free Shipping',

        radioButtonOptions: ['Red'
          ,'Green'
          ,'Pink'
          ,'Blue'
          ,'Turquoise'
        ],

        buttonLabel: 'Add to Cart'

      },

    };

    const faqButtonClickHandler = () => {
      console.log('FAQ Button Click');
    };

    const chooseColorButtonClickHandler = () => {
      console.log('Choose Color Button Click');
    };

    return {
      content,
      faqButtonClickHandler,
      chooseColorButtonClickHandler,
    }

  }

}

</script>

<style lang="scss" scoped>

.addCart-wrapper {
  margin-top: 100px;
}

.addToCart {
  margin-top: 50px;
}

.fullscreen-image {
  max-width: 100%;
  width: 100%;
  padding: 15px;
  margin-top: 30px;
}

.mobile-section {
  display: none;
}

.small-image {
  max-width: 100%;
  padding: 15px;
  margin-top: 100px;
}

.learnToDrink {
  margin-top: 25px;
}

.single-heading {
  text-align: center;
  margin: 100px 0;
  font-size: 1.8em;
  font-weight: 400;

  &.red {
    display: none;
    color: #d0021b;
  }

}

.feature-section {

  img {
    width: 100%;
    margin-bottom: 25px;
  }

}

.gallery-wrapper {
  margin-top: 50px;
}

.reviews-section {
  font-size: 1.3em;
}

.bundle-section {
  display: none;
}

@media only screen and (max-width: 1024px ) and (min-width: 426px) {

  .learnToDrink {
    display: none;
  }

}

@media only screen and (max-width: 426px) {

  .addCart-wrapper {
    margin-top: 50px;
  }

  .single-heading {
    margin: 25px 0;

    &.red {
      display: block;
    }

  }

  .mobile-section {
    display: block;
  }

  .bundle-section {
    display: block;
  }

}

</style>