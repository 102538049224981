<template>

  <div class="soEasy-section">

    <div class="grid-container">

      <div class="content">

        <h1>{{ content.heading }}</h1>

        <div class="description" v-html="content.description"></div>

        <img class="gif" :src="content.gifSrc" alt="gif">

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'SoEasy',

  props: ['content'],

}

</script>

<style lang="scss" scoped>

.soEasy-section {

  .content {
    max-width: 100%;
    width: 100%;
    padding: 100px 70px;
    color: white;
    background-color: #f9784d;
    font-size: 2em;

    h1 {
      text-transform: uppercase;
      font-size: 2em;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 10px;
    }

    .description {
      font-size: 1.5em;
      line-height: normal;
      margin-bottom: 30px;
    }

    img {
      max-width: 100%;
      width: 100%;
      margin-top: 50px;
    }

  }

}

@media only screen and (max-width: 1024px) {

  .soEasy-section {

    .content {
      font-size: 1.4em;
    }

  }

}

@media only screen and (max-width: 425px) {

  .soEasy-section {
    margin-top: 0;

    .grid-container {
      background-color: #ffcb01;
    }

    .content {
      padding: 50px 15px;
      color: #d0021b;
      font-size: 1em;
      background-color: unset;

      h1 {
        margin-bottom: 20px;
        text-align: center;
      }

      img {
        margin-top: 20px;
      }

    }

  }

}



</style>