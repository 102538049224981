<template>

  <div class="advantages-section">

    <div class="grid-container">

      <div class="content">

        <div class="left-side">

          <div class="advantages">

            <ul>
              <li
                  v-for="(advantage, index) in content.advantages"
                  :key="index"
              >
                {{ advantage }}
              </li>
            </ul>

          </div>

          <div class="products">

            <div
                v-for="(product, index) in content.products"
                :key="index"
                class="product"
            >

              <i class="far fa-check-circle"/>

              <span>{{ product }}</span>

            </div>

          </div>

        </div>

        <div class="right-side">

          <img :src="content.imgSrc" alt="product">

        </div>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'Advantages',

  props: ['content'],

}

</script>

<style lang="scss" scoped>

.advantages-section {

  .content {
    display: flex;
    align-items: center;
    gap: 20px;

    .left-side, .right-side {
      width: 50%;
      flex-grow: 1;
    }

    .left-side {

      ul {
        font-size: 0.8em;
      }

      .products {
        font-size: 0.6em;
        padding: 20px 50px 20px 80px;

        .product {

          i {
            margin-right: 10px;
          }

        }

      }

    }

    .right-side {

      img {
        max-width: 100%;
        width: 100%;
      }

    }

  }

}

@media only screen and (max-width: 426px) {

  .advantages-section {

    .content {
      flex-direction: column;

      .left-side, .right-side {
        width: 100%;
      }

      .left-side {

        .products {
          font-size: 0.9em;
        }

      }

    }

  }

}

</style>