<template>

  <div class="hero" :style="{ backgroundImage: 'url(' + content.backgroundImage + ')' }">

    <div class="overlay"/>

    <div class="grid-container">

      <div class="content-wrapper">

        <div class="content">

          <img :src="content.logo" alt="background image">

          <h1>{{ content.title }}</h1>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'Hero',

  props: ['content'],

}

</script>

<style lang="scss" scoped>

.hero {
  position: relative;
  min-height: 400px;
  background-size: cover;
  background-position: 50% 50%;

  .overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: absolute;
    overflow: hidden;
    background-color: rgba(155, 155, 155, 0.67);
    z-index: 1;
  }

  .content-wrapper {
    position: relative;
    z-index: 2;
    line-height: 1.5;
    padding: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .content {
      max-width: 90%;

      img {
        max-width: 80%;
      }

      h1 {
        font-size: 2.3em;
        color: white;
      }

    }

  }

}

@media only screen and (max-width: 425px) {

  .hero {

    .content-wrapper {
      padding: 70px 20px;

      .content {
        max-width: 100%;

        h1 {
          font-size: 1.7em;
        }

      }

    }

  }

}

</style>