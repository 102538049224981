<template>

  <div class="insurances-section">

    <div class="grid-container">

      <div class="content">

        <div class="left-side">

          <img :src="content.leftImg" alt="product">

        </div>


        <div class="right-side">

          <h1> FAST, EASY, SECURE </h1>

          <div class="insurances-wrapper">

            <div
                v-for="(insurance, index) in content.insurances"
                :key="index"
                class="insurance-block">

              <img :src="insurance.imgSrc" alt="block-image">
              <span>{{ insurance.title }}</span>
              <p>{{ insurance.description }}</p>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'Insurances',

  props: ['content'],

}

</script>

<style lang="scss" scoped>

.insurances-section {
  margin-top: 40px;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;

    .left-side {
      width: 100%;

      img {
        max-width: 100%;
      }

    }

    .right-side {
      text-align: center;

      h1 {
        font-size: 1.5em;
        color: #2d3339;
      }

      .insurances-wrapper {
        margin: 5% auto 0;
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-auto-rows: minmax(100px,auto);
        gap: 20px;

        .insurance-block {
          border: 2.5px solid #d1cfcf;
          border-radius: 12px;
          text-align: center;
          padding: 10px;
          height: fit-content;

          img {
            max-width: 100%;
            width: 100%;
          }

          span {
            font-size: 0.8em;
            font-weight: 700;
            color: #2d3339;
          }

          p {
            padding-top: 25px;
          }

        }

      }

    }

  }

}

@media only screen and (max-width: 768px) {

  .insurances-section {

    .content {
      flex-direction: column;
      gap: 25px;

      .left-side {

        img {
          width: 100%;
        }

      }

    }

  }

}

@media only screen and (max-width: 425px) {

  .insurances-section {

    .content {

      .right-side {
        padding: 0 10px;
        max-width: 100%;

        .insurances-wrapper {
          grid-template-columns: repeat(1, auto);
          padding: 0 10px;
        }

      }

    }

  }

}

</style>