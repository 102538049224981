<template>

  <div class="reviews-section" :style="{backgroundImage: 'url(' + content.bgImage + ')'}">

    <div class="grid-container">

      <h1>{{ content.title }}</h1>

      <div class="reviews-wrapper">

        <div
            v-for="(review, index) in content.reviews"
            :key="index"
            class="review">

          <p class="description" v-if="review.reviewer">
            {{ review.description }}
          </p>

          <vue3-star-ratings
              :showControl="false"
              :disableClick="true"
              starColor="#FFD700"
              v-model="review.rating"
              v-if="review.rating"
          />

          <span class="reviewer" v-if="review.reviewer">
              {{ review.reviewer }}
          </span>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'Reviews',

  props: ['content'],

  components: {},

  setup() {

    return {}
  }


}

</script>

<style lang="scss" scoped>

.reviews-section {
  margin-top: 50px;
  padding: 20px 0;

  h1 {
    text-transform: uppercase;
    font-size: 30px;
    text-align: center;
  }

  .reviews-wrapper {
    margin: 5% auto 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
    align-items: flex-start;
    max-width: 100vw;

    .review {
      display: inline-flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      margin: 20px;
      padding: 50px;
      border: 3px solid #9013fe;
      border-radius: 20px;
      background-color: #fff;
      text-align: left;

      .description {
        line-height: 1.3em;
        font-size: 0.8em;
      }

      .vue3-star-ratings__wrapper {
        margin: 15px 0;
        padding: 0;
        align-self: center;
      }

      .reviewer {
        color: #4e1353;
        font-weight: 700;
      }

    }

  }

}

@media only screen and (max-width: 1024px) {

  .reviews-section {

    h1 {
      padding: 0 20px;
      text-align: center;
    }

    .reviews-wrapper {

      .review {
        padding: 20px;
        margin: 10px;
      }

    }

  }

}

@media only screen and (max-width: 767px) {

  .reviews-section {

    .reviews-wrapper {
      grid-template-columns: repeat(1, 1fr);
    }

  }

}


</style>