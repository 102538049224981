<template>
  <div id="nav">
    <router-link :to="{name: 'LollaLandHome'}">Lollaland</router-link>
    <router-link :to="{name: 'TieBossKayaksHome'}">Kayaks</router-link>
    <router-link :to="{name: 'TieBossAtvsHome'}">ATVs</router-link>
    <router-link :to="{name: 'KizingoKids'}">KizingoKids</router-link>
  </div>
  <router-view/>
</template>

<script>

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
//font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
  width: 100%;
  background: #ffffff;
  font-family: 'Open Sans', sans-serif;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1:not(.site-title):before {
  content: none;
}

h2:before {
  content: none;
}

*:focus {
  outline: none;
}

button {
  text-decoration: none;

&:hover, &:focus {
            text-decoration: none;
          }
}

.grid-container {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  padding: 15px;
  overflow: hidden;
}

#nav {
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding-right: 20px;
  a {
    color: white;
    text-decoration: none;
    padding: 10px 0;
  }
}

</style>
