<template>

  <div class="gallery-wrapper">

    <div class="grid-container">

      <div class="gallery">

        <img
            v-for="(imgSrc, index) in content"
            :key="index"
            :src="imgSrc" alt="gallery-photo">

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'Gallery',

  props: ['content'],

}

</script>

<style lang="scss" scoped>

.gallery-wrapper {

  .gallery {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-auto-rows: minmax(100px,auto);
    row-gap: 20px;
    max-width: 100%;
    width: 100%;

    img {
      max-width: 100%;
      align-self: start;
      justify-self: center;
    }

  }

}

@media only screen and (max-width: 425px) {

  .gallery-wrapper {

    .gallery {
      grid-template-columns: repeat(1 ,1fr);

      img {
        width: 100%;
      }

    }

  }

}

</style>