<template>

  <div class="hero" :style="{ backgroundImage: 'url(' + content.backgroundImage + ')' }">

    <div class="overlay"/>

    <div class="grid-container">

      <div class="content">

        <img :src="content.logo" alt="background image">

        <h1>{{ content.title }}</h1>

        <p>{{ content.description }}</p>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'Hero',

  props: ['content'],

}

</script>

<style lang="scss" scoped>

.hero {
  position: relative;
  min-height: 400px;
  background-size: cover;
  background-position: 50% 50%;

  .overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: absolute;
    overflow: hidden;
    background-color: rgba(155, 155, 155, 0.52);
    z-index: 1;
  }

  .content {
    position: relative;
    z-index: 2;
    line-height: 1.5;
    padding: 70px;
    background-color: rgba(255,255,255,.3);
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    img {
      max-width: 80%;
    }

    p {
      color: white;
      font-weight: 700;
    }

  }

}

@media only screen and (max-width: 426px) {

  .hero {
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      position: relative;
      top: unset;
      left: unset;
      transform: translate(0, 0);
      max-width: 90%;
      padding: 30px;
      margin: 0 auto;
    }

  }

}


</style>