<template>

  <div class="accordion">

    <accordion-item
        v-for="(item, i) in content"
        :key="i"
        :content="item"
        :index="i"
        :open="item.open"
        @toggleOpen="toggleOpen"
    />

  </div>

</template>

<script>
import AccordionItem from "@/components/AccordionItem";
import {ref} from "vue";

export default {

  name: 'Accordion',

  props: ['content'],

  components: {
    AccordionItem,
  },

  setup(props) {

    const data = ref();

    data.value = props.content;

    const toggleOpen = (index) => {
      data.value = data.value.map((item, i) => {
        if(index === i) {
          item.open = !item.open;
        }
        else {
          item.open = false;
        }

        return item;
      });

    }

    return {
      toggleOpen,
    }

  }

}

</script>

<style lang="scss" scoped>


</style>