<template>

  <div class="hero-section" :style="{ backgroundImage: 'url(' + content.backgroundImage + ')' }"/>

  <div class="hero-section-mobile" :style="{ backgroundImage: 'url(' + content.backgroundImageMobile + ')' }" />

</template>

<script>

import YoutubeVideo from "@/components/YoutubeVideo";

export default {

  name: 'Hero',

  props: ['content'],

  components: {
    YoutubeVideo,
  }

}

</script>

<style lang="scss" scoped>

.hero-section {
  background-size: cover;
  background-position: 50% 0;
  min-height: 650px;
}

.hero-section-mobile {
  display: none;
  background-position: right center;
  background-size: cover;
  background-attachment: local;
  background-repeat: repeat;
  min-height: 300px;
}

@media only screen and (max-width: 1024px) {

  .hero-section {
    min-height: 400px;
  }

}

@media only screen and (max-width: 425px) {

  .hero-section {
    display: none;
  }

  .hero-section-mobile {
    display: block;
  }

}

</style>