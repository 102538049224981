<template>

  <div class="grid-container">

    <div class="addCart-wrapper">

      <div class="productImages-wrapper">

        <div
            v-for="(productImg, index) in content.leftImages"
            :key="index"
            class="productImage-wrapper">

          <img :class="{'desktop-image': index > 0}" :src="productImg" alt="product">

        </div>

      </div>

      <add-to-cart
          v-if="type === 1"
          :content="content.rightContent"
      />

      <add-to-cart2
          v-if="type === 2"
          :content="content.rightContent"
      />

    </div>

  </div>

</template>

<script>
import AddToCart from "@/components/TieBoss/Kayaks/AddToCart";
import AddToCart2 from "@/components/TieBoss/Kayaks/AddToCart2";

export default {

  name: 'AddCartWithImages',

  props: {

    content: {
      type: Object,
      required: true,
    },

    type: {
      type: Number,
      default: 1,
    }
  },

  components: {
    AddToCart,
    AddToCart2,
  },

}

</script>

<style lang="scss" scoped>

.addCart-wrapper {
  display: flex;
  gap: 30px;

  .productImages-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    width: 50%;
    flex-grow: 1;
    gap: 15px;

    .productImage-wrapper {
      width: 100%;

      img {
        max-width: 100%;
        width: 100%;
      }

    }

  }

  .addToCart-section {
    max-width: 50%;
    flex-grow: 1;
  }

}

@media only screen and (max-width: 426px) {

  .addCart-wrapper {
    flex-direction: column;

    .productImages-wrapper {
      max-width: 100%;
      width: 100%;

      .desktop-image {
        display: none;
      }
    }

    .addToCart-section {
      max-width: 100%;
    }

  }

}

</style>