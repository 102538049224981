<template>

  <div class="product-info-section">

    <div class="grid-container">

      <div class="content">

        <div class="image-wrapper">

          <img :src="content.leftContent.desktopImage" alt="baby-photo" class="desktop-image">

          <img :src="content.leftContent.tabletImage" alt="baby-photo" class="tablet-image">

          <img :src="content.leftContent.mobileImage" alt="baby-photo" class="mobile-image">

        </div>

        <div class="right-info">

          <div
              v-for="(data, index) in content.rightContent"
              :key="index"
              class="info-block">

            <div class="image-wrapper">
              <img :src="data.imgSrc" alt="picture">
            </div>


            <div class="right-content">
              <span class="heading">{{ data.heading }}</span>
              <span class="sub-heading">{{ data.subHeading }}</span>
            </div>

          </div>

        </div>


      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'ProductInfo',

  props: ['content'],

}

</script>

<style lang="scss" scoped>

.product-info-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 25px;

    .image-wrapper, .right-info {
      width: 50%;
      flex-grow: 1;
    }

    .image-wrapper {

      img {
        max-width: 100%;
        width: 100%;

        &.tablet-image, &.mobile-image {
          display: none;
        }

      }

    }

    .right-info {

      .info-block {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;
        margin-bottom: 70px;
        padding: 0 10px;

        .image-wrapper {
          display: flex;
          justify-content: center;
          width: 25%;

          img {
            width: 50px;
            height: 50px;
          }

        }

        .right-content {
          display: flex;
          flex-direction: column;
          width: 75%;

          .heading {
            font-size: 1.5em;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 15px;
            color: #333;
            line-height: 1em;
          }

        }

      }

    }

  }

}

@media only screen and (max-width: 1024px) {

  .product-info-section {

    .content {

      .image-wrapper {

        img {
          max-width: 100%;
          width: 100%;

          &.tablet-image {
            display: block;
            margin-top: 50px;
          }

        }

      }

    }

  }

}

@media only screen and (max-width: 425px) {

  .product-info-section {

    .content {
      flex-direction: column;

      .image-wrapper, .right-info {
        width: 100%;
        flex-grow: 1;
      }

      .image-wrapper {

        img {

          &.desktop-image, &.tablet-image {
            display: none;
          }

          &.mobile-image {
            display: block;
          }

        }

      }

    }

  }

}

</style>