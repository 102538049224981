<template>

  <div class="strategy-section">

    <div class="grid-container">

      <div class="content">

        <span class="title">{{ content.title }}</span>

        <h1>{{ content.subtitle }}</h1>

        <div class="main-content">

          <div class="left-content">

            <img :src="content.leftImg" :alt="content.leftImgAlt">

          </div>

          <div class="right-content">

                <p
                    v-for="(paragraph, index) in content.paragraphs"
                    :key="index"
                > {{ paragraph }}</p>

          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'Strategy',

  props: ['content'],

}

</script>

<style lang="scss" scoped>

.strategy-section {
  background-color: #9b9b9b45;

  .content {
    text-align: center;
    color: #d0021b;
    font-size: 1.8em;

    h1 {
      margin-top: 15px;
      font-size: 1.3em;
    }

    .main-content {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 50px;
      text-align: left;
      margin-top: 50px;

      .left-content {
        width: 60%;

        img {
          max-width: 100%;
          width: 100%;
        }

      }

      .right-content {
        width: 40%;
        padding: 0 25px;

        p {
          margin-bottom: 30px;
        }

      }

    }

  }

}

@media only screen and (max-width: 1024px) {

  .strategy-section {

    .content {

      .title {
        font-size: 0.7em;
      }

      h1 {
        color: white;
      }

      .main-content {
        flex-direction: column;

        .left-content, .right-content {
          width: 100%;
        }

        .left-content {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 500px;
          }
        }

      }

    }

  }

}

@media only screen and (max-width: 425px) {

  .strategy-section {

    .content {
      font-size: 1.4em;


      h1 {
        color: #d0021b;
      }

      .main-content {
        flex-direction: column;

        .left-content, .right-content {
          width: 100%;
        }

        .left-content {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 500px;
          }
        }

      }

    }

  }

}

</style>