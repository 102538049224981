<template>

  <div class="addToCart-section">

    <h1 class="title">{{ content.title }}</h1>

    <p class="subtitle">{{ content.subtitle }}</p>

    <p class="original-price"> &nbsp {{ content.originalPrice }} &nbsp </p>

    <sc-separator color="#919da9"/>

    <div class="offers">

      <p
          v-for="(offer, index) in content.offers"
          :key="index"
      >
        {{ offer }}
      </p>

    </div>

    <p class="additional-info"> {{ content.additionalInfo }} </p>

    <sc-separator color="#919da9"/>

    <h1 class="pack-content-title">{{ content.packContentTitle }}</h1>

    <ul>
      <li
          v-for="(item, index) in content.packageContent"
          :key="index"
      >
        {{ item }}
      </li>
    </ul>

    <sc-separator color="#919da9"/>

    <img class="product-image" :src="content.productImg" alt="product">

    <div class="colors-wrapper">

      <span>Color</span>

      <div
          v-for="(color, index) in content.productColors"
          :key="index"
          class="option">
        <label>
          <input
              type="radio"
              v-model="chosenOption"
              :value="color"
              :checked="index === 0"
              @change="radioChangeHandler(index)"
          > {{ color }} </label>
      </div>

    </div>

    <sc-separator color="#919da9"/>

    <div class="counter-button-wrapper">

      <counter @counter-change="counterChangeHandler($event)"/>

      <div class="add-button" @click="addToCartHandler">
        <i class="fas fa-shopping-cart"/>
        <span>{{ content.submitButtonLabel }}</span>
      </div>

    </div>

    <span class="shipping-info">{{ content.shippingInfo }}</span>

    <img class="certifications" :src="content.certificationsImg" alt="certification">

  </div>

</template>

<script>

import {ref} from "vue";
import Counter from '@/components/Counter.vue';
import ScSeparator from "@/components/ScSeparator";

export default {

  name: 'AddToCart',

  props: ['content'],

  components: {
    Counter,
    ScSeparator,
  },

  setup(props, {emit}) {

    const counterValue = ref(1)

    const chosenOption = ref();

    chosenOption.value = props.content.productColors[0];

    const radioChangeHandler = (index) => {
      emit("changedRadioOption", index);
    };

    const counterChangeHandler = (value) => {
      counterValue.value = value;
    }

    const addToCartHandler = () => {
      console.log('Chosen options: ' + chosenOption.value);
      console.log('Chosen quantity: ' + counterValue.value);
    }

    return {
      radioChangeHandler,
      counterChangeHandler,
      addToCartHandler,
      counterValue,
      chosenOption,
    }

  }

}

</script>

<style lang="scss" scoped>

.addToCart-section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px;

  .title {
    font-weight: bold;
    color: rgb(0, 55, 0);
    margin-bottom: 0;
    font-size: 1.5em;
  }

  .subtitle {
    font-size: 0.8em;
  }

  .original-price {
    font-weight: 700;
    text-decoration: line-through;
    color: rgb(136, 136, 136);
  }

  .offers {
    font-weight: 700;
    color: rgb(0, 55, 0);
    width: 100%;
  }

  .additional-info {
    font-size: 0.8em;
  }

  .pack-content-title {
    align-self: flex-start;
    margin-bottom: 20px;
    font-size: 0.7em;
  }

  ul {
    align-self: flex-start;
  }

  .product-image {
    width: 302px;
    margin-top: 20px;
    margin-bottom: 10px;
    align-self: center;
  }

  img {
    max-width: 100%;
  }

  .colors-wrapper {
    width: 100%;
    padding: 10px;

    .option {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      font-size: 0.7em;
      margin-top: 20px;

      label, input[type=radio] {
        cursor: pointer;
      }
    }

  }

  .counter-button-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
  }

  .counter-wrapper {
    margin: 10px 0;
  }

  .add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    cursor: pointer;
    background-color: #d0021b;
    color: #fff;
    padding: 10px;
    font-size: 19px;
    line-height: 1.5;
    font-weight: 700;
    border-radius: 20px;
    margin: 10px 0;

    span {
      font-size: 29px;
      line-height: 1;
      font-weight: 700;
      text-align: center;
      letter-spacing: -1px;
      margin-left: 10px;
    }
  }

  .shipping-info {
    font-size: 0.8em;
    align-self: center;
    text-align: center;
  }

  .certifications {
    margin-top: 15px;
    width: 100%;
  }

}

@media only screen and (max-width: 768px) {

  .addToCart-section {

    .counter-button-wrapper {
      flex-direction: column;
      gap: 0;
    }

  }

}

@media only screen and (max-width: 425px) {

  .addToCart-section {

    .title {
      font-size: 1.2em;
    }

    .pack-content-title {
      font-size: 1em;
    }

  }

}

</style>