<template>

  <div class="faq-section">

    <div class="grid-container">

      <div class="heading">

        <h1 :style="{ color: titleColor }">{{content.title}}</h1>

      </div>

      <div class="content">

        <accordion :content="content.faqs"/>

      </div>

    </div>

  </div>

</template>

<script>
import Accordion from "@/components/KizingoKids/Accordion";

export default {

  name: 'FAQ',

  props: ['content', 'titleColor'],

  components: {
    Accordion,
  },

}

</script>

<style lang="scss" scoped>

.faq-section {

  .heading {
    width: 100%;
    padding: 5px;
    background-color: #4a90e238;
    margin-bottom: 20px;
    border-radius: 10px;

    h1 {
      text-align: center;
      font-size: 1.5em;
      margin-bottom: 0;
    }

  }

  .content {
    width: 100%;
    padding: 15px 0;
  }

}

@media only screen and (max-width: 425px) {

  .faq-section {

    .heading {

      h1 {
        font-size: 1.2em;
        line-height: 1.3;
      }

    }

  }

}

</style>