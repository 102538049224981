<template>

  <div class="modal-section">

    <div class="modal-content">

      <i class="fas fa-times" @click="closeModal"></i>

      <div class="image-section">
        <img :src="content.imgSrc" alt="modalInfoPicture" class="modal-picture">
      </div>

      <div class="text-section">
        <h1 class="title" v-if="content.hasOwnProperty('title')">{{ content.title }}</h1>

        <p v-if="content.hasOwnProperty('subtitle')">{{ content.subtitle }}</p>

        <input type="text" id="clientName" name="clientName" placeholder="First Name" v-if="content.clientInfo.hasOwnProperty('name')">

        <input type="email" id="email" name="email" placeholder="Email Address" v-if="content.clientInfo.hasOwnProperty('email')">

        <div class="button" v-if="content.hasOwnProperty('buttonLabel')">
          <p>{{ content.buttonLabel}}</p>
        </div>
      </div>

    </div>

    <div class="modal-backdrop" @click="closeModal">

    </div>

  </div>

</template>

<script>

import {ref} from "vue";

export default {

  name: 'Modal',

  props: ['content'],

  setup(props, {emit}) {

    const closeModal = () => {
      emit("closeModal");
    }

    return {
      closeModal,
    }

  }

}

</script>

<style lang="scss" scoped>

.modal-section {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  height: 100vh;
  width: 100vw;

  .modal-content {
    min-height: 50%;
    min-width: 50%;
    background-color: rgb(242, 242, 242);
    padding: 25px;
    border-radius: 8px;
    z-index: 6;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    gap: 20px;

    .fa-times {
      position: absolute;
      right: 10px;
      top: 5px;
      color: grey;
      font-size: 20px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }

    i:hover {
      transform: rotateZ(90deg);
    }

    .modal-picture {
      width: 200px;
      height: 200px;
    }

    .title {
      color: #b52555;
      font-size: 20px;
    }

    input[type=text], [type=email] {
      width: 100%;
      padding: 6px 10px;
      margin: 8px 0;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }

    .button {
      background-color: #b52555;
      color: white;
      text-align: center;
      font-weight: bold;
      padding: 10px;
      cursor: pointer;
    }
  }

  .modal-backdrop {
    background-color: rgb(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
  }

}

@media only screen and (max-width: 2561px) and (min-width: 1024px) {
  .modal-section {
    .modal-content {
      min-height: 260px;
      min-width: 45%;
    }
  }
}

@media only screen and (max-width: 1023px) and (min-width: 427px) {
  .modal-section {
    .modal-content {
      min-height: 260px;
      min-width: 60%;
    }
  }
}

@media only screen and (max-width: 426px) {
  .modal-section {
    .modal-content {
      min-height: 260px;
      min-width: 90%;
      display: flex;
      flex-direction: column;

      .image-section {
        display: none;
      }
    }
  }
}

</style>