<template>

  <div class="bannerModal-section">

    <div class="bannerModal-content">

      <i class="fas fa-times" @click="closeModal"></i>

      <h1 class="title" v-if="content.hasOwnProperty('title')">{{ content.title }}</h1>

      <p v-if="content.hasOwnProperty('info')">{{ content.info }}</p>

      <input type="email" id="email" name="email" placeholder="Enter email..." v-if="content.hasOwnProperty('inputLabel')">

      <div class="button" v-if="content.hasOwnProperty('buttonLabel')">
        <p>{{ content.buttonLabel}}</p>
      </div>
    </div>

    <div class="bannerModal-backdrop" @click="closeModal">

    </div>


  </div>

</template>

<script>

import {ref} from "vue";

export default {

  name: 'BannerModal',

  props: ['content'],

  setup(props, {emit}) {

    const closeModal = () => {
      emit("closeModal");
    }

    return {
      closeModal,
    }

  }

}

</script>

<style lang="scss" scoped>

.bannerModal-section {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  height: 100vh;
  width: 100vw;

  .bannerModal-content {
    height: 200px;
    width: 400px;
    background-color: rgb(242, 242, 242);
    padding: 20px;
    border-radius: 10px;
    z-index: 6;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 20px;

    .fa-times {
      position: absolute;
      right: 15px;
      top: 5px;
      color: black;
      font-size: 20px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }

    i:hover {
      transform: rotateZ(90deg);
    }


    .title {
      color: rgb(34, 72, 140);
      font-size: 20px;
    }

    .button {
      background-color: black;
      color: white;
      text-align: center;
      text-transform: uppercase;
      padding: 10px;
      cursor: pointer;
    }
  }

  .bannerModal-backdrop {
    background-color: rgb(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
  }

}

@media only screen and (max-width: 426px) {

  .bannerModal-section {
    .bannerModal-content {
      height: 200px;
      width: 90%;
    }
  }

}

</style>