<template>

  <div class="kizingo-kids">

    <Hero :content="content.heroSection"/>

    <youtube-video :content="content.youtubeSection" bgColor="#bd10e01a"/>

    <importance :content="content.importanceSection"/>

    <div class="grid-container">

      <sc-separator color="#919da9"/>

    </div>

    <div class="grid-container">

      <div class="awards-section">

        <h1>{{ content.awardsSection.title }}</h1>

        <img :src="content.awardsSection.imgSrc" :alt="content.awardsSection.imgSrcAlt">

      </div>

    </div>

    <div class="grid-container">

      <sc-separator color="#919da9"/>

    </div>

    <add-cart-with-images :content="content.addToCartSection"/>

    <div class="grid-container">

      <sc-separator color="#919da9"/>

    </div>

    <div class="grid-container">
      <img :src="content.singleImage.src" :alt="content.singleImage.alt" class="fullscreen-image">
    </div>

    <f-a-q :content="content.faqSection"/>

    <reviews id="reviews" :content="content.reviewsSection"/>

    <gallery :content="content.gallerySection"/>

    <div class="grid-container">

      <sc-separator color="#919da9"/>

    </div>

    <add-cart-with-images :content="content.addToCartSection2"/>

    <div class="grid-container">

      <sc-separator color="#919da9"/>

    </div>

    <design :content="content.designSection"/>

  </div>

  <transition name="fade">
    <modal v-if="content.modalSection.hasOffer && isOpened" :content="content.modalSection" @closeModal="isOpened = false" id="modalOffer">

    </modal>
  </transition>

</template>

<script>

import Hero from "@/components/KizingoKids/Hero";
import YoutubeVideo from "@/components/YoutubeVideo";
import Importance from "@/components/KizingoKids/Importance";
import ScSeparator from "@/components/ScSeparator";
import AddCartWithImages from "@/components/KizingoKids/AddCartWithImages";
import FAQ from "@/components/KizingoKids/FAQ";
import Reviews from "@/components/KizingoKids/Reviews";
import Gallery from "@/components/KizingoKids/Gallery";
import Design from "@/components/KizingoKids/Design";
import {ref, onMounted} from "vue";
import Modal from "@/components/KizingoKids/Modal";
import $ from 'jquery';


export default {

  name: 'KizingoKids',

  components: {
    Modal,
    Hero,
    YoutubeVideo,
    Importance,
    ScSeparator,
    AddCartWithImages,
    FAQ,
    Reviews,
    Gallery,
    Design,
  },

  setup() {

    onMounted(() => {
      loadModal();
    })


    const content = ref();

    const isOpened = ref(false);

    content.value = {

      heroSection: {

        backgroundImage: 'https://cdn.shopify.com/s/files/1/1194/7512/t/17/assets/zing-with-logo-1635619391430.png?v=1635619392',
        backgroundImageMobile: 'https://cdn.shopify.com/s/files/1/1194/7512/t/17/assets/kizingo-pic-22-1-1635623391224.png?v=1635623391',

      },

      youtubeSection: {

        title: 'Don\'t Hold Your Child Back - They Are Ready to Go',

        ytSrc: 'https://www.youtube.com/embed/O-AZRzAgY8E',

      },

      importanceSection: {

        title: 'Give Your Child the Headstart They Deserve',

        subtitle: 'WHY IS SELF-FEEDING SO IMPORTANT?',

        leftImg: 'https://cdn.shopify.com/s/files/1/1194/7512/t/17/assets/hnetcomimage-1-1633923087823_500x.gif?v=1633923088',

        leftImgAlt: 'Product',

        reasons: [

          {
            title: 'DEVELOPMENT OF FINE MOTOR SKILLS',
            description: 'Grip development and Hand Eye Coordination',
          },

          {
            title: 'HELPS TO PREVENT CHILDHOOD OBESITY',
            description: 'They know when they are full. Gone are the days of "eating everything on your plate".  A healthy relationship with food STARTS NOW!! ',
          },

          {
            title: 'NO PICKY EATERS',
            description: 'Children often associate "frustration" with the introduction of new foods.  Relieving this frustration is a key factor in preventing the development of picky eaters.  ',
          },

        ],

      },

      awardsSection: {

        title: 'Humbled and Honored By Our Product Awards',

        imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/t/17/assets/award-badges-1633942663065_500x.png?v=1633942663',

        imgSrcAlt: 'Awards Banner',

      },

      addToCartSection: {

        leftImages: [

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/Kizing-curved-baby-and-toddler-spoon-supports-self-feeding_x593@2x.jpg?v=1619668597'
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/Kizingo-curved-toddler-spoons-make-feeding-easy_x593@2x.png?v=1619668597',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/Kizingo-toddler-spoons-for-baby-led-weaning_x593@2x.png?v=1619668597',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/curved-toddler-spoons-make-self-feeding-easy_x593@2x.png?v=1619668597',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/kizingo-curved-spoons-designed-for-self-feeding_x593@2x.png?v=1619668597',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/kizingo-curved-toddler-spoons-designed-by-doctor-moms_x593@2x.png?v=1619668597',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/Raspberry-Lagoon-Twin-Pack-01_x593@2x.png?v=1619668597',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/Mango-Wave-twin-pack-spoons-01_x593@2x.png?v=1619668597',
            id: 1
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/Raspberry-Lagoon-Twin-Pack-01_x593@2x.png?v=1644205682',
            id: 2
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/peachpeach_1_50_1dad1785-4802-4d0f-bcc2-86b4a944fc80_x593@2x.jpg?v=1647010351',
            id: 3
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/yellowlightblue_small-01_x593@2x.png?v=1653850756',
            id: 4
          },

        ],

        rightContent: {

          title: 'Right - Handed Curved Spoon Twin Pack (2 spoons)',

          originalPrice: '$16.99',

          ratingValue: 4.5,

          numberOfReviewers: 57,

          price: '$14.99 and Free Shipping',

          productCharacteristics: [
            'DISHWASHER SAFE',
            'NO NASTY CHEMICALS: BPA/PVC/Phthalate-free plastic',
            'UNBREAKABLE',
            'PERFECTLY SIZED FOR LITTLE HANDS',
            'DESIGNED, TESTED, AND USED BY A DR. MOM.'
          ],

          productColors: [

            {
              id: 1,
              label: 'Wave and Mango',
            },

            {
              id: 2,
              label: 'Raspberry and Lagoon',
            },

            {
              id: 3,
              label: 'Peach and Peach',
            },

            {
              id: 4,
              label: 'Honey and Electric',
            },

          ],

          submitButtonLabel: 'Add to Cart',

          shippingInfo: 'SHIPS FREE IN 1 BUSINESS DAY',

          certificationsImg: 'https://cdn.shopify.com/s/files/1/1194/7512/t/17/assets/leggo--edited-1633945144272_453x.jpg?v=1633945143',

        }

      },

      singleImage: {

        src: 'https://cdn.shopify.com/s/files/1/1194/7512/t/17/assets/curvedbydesign-1-1635619900013_500x.png?v=1635619900',

        alt: 'image alt'

      },

      faqSection: {

        title: 'Frequently Asked Questions',

        faqs: [

          {
            heading: 'What makes Kizingo Spoons so special?',

            additionalInfo: [
              'We typically ship orders placed before 12 PM EST the same business day. All other orders ship in 1 business day.',
              'Encourage independence',
              'Are curved to work with the way children want use them',
              'Have short, wide handles that are easy to hold',
              'Are Dishwasher safe',
              'Are 100% designed in the US',
              'Don’t contain BPA/BPS/PVC/Lead or Phthalates'

            ],

            open: false,
          },

          {
            heading: 'Can I put your products in the dishwasher?',

            additionalInfo: [
              'Heck yeah! Can you imagine a toddler spoon or plate that you had to hand wash?',
              'Our products are designed for parents, by parents, so yes ... they are dishwasher safe.',
              'We wouldn\'t recommend putting them in the microwave, though, although it won\'t ruin the utensils or tableware if you do.',
            ],

            open: false,
          },

          {
            heading: 'What are Kizingo products made of?',

            additionalInfo: [
              ' The highest-quality, FDA-approved, dishwasher-safe plastic they can be. All of our products are made from FDA-approved BPA/PVC/Lead/Phthalate-free Profax 511 (aka: polypropylene, aka: plastic).',
              'During the design and manufacturing processes, we tested a number of different materials - all plastic co-polymers - and selected the best one we could to balance safety, feel, and manufacturability.',
              'One day we may explore other materials, so keep coming back to see what new products - and materials - we\'re offering!',
            ],

            open: false,
          },

          {
            heading: 'Are your spoons really handed?',

            additionalInfo: [
              'YES. Although we have observed children using our spoons with both their right and left hands, the current spoon design is specific to a particular hand. It works best when used that way.',
              'BUT.....We do have a left - handed version.  You can get it HERE:',
              'GET MY LEFT HANDED SPOON',
            ],

            open: false,
          },

          {
            heading: 'Where are Kizingo products made?',

            additionalInfo: [
              'Our products are 100% designed in the US and responsibly made by our partners in China and Thailand. ',
            ],

            open: false,
          },

        ],

      },

      reviewsSection: {

        title: 'Parents Approve!',

        bgImage: 'https://cdn.shopify.com/s/files/1/1194/7512/t/17/assets/img_7493-1633977137220.jpg?v=1633977137',

        reviews: [

          {
            description: '"I am an Occupational Therapist working for Des Moines Public schools. I have tried your spoon Kizingo with children in the Early Childhood Preschool Program and love it!"',
            rating: 5,
            reviewer: 'Mary Jo',
          },

          {
            description: '"I love this spoon - my 2 year old is a fiercely independent eater and now he can use a utensil without any of the mess. He is proud and so am I "',
            rating: 5,
            reviewer: 'Anne W.',
          },

          {
            description: '"My baby that insists on self feeding loves this spoon!"',
            rating: 5,
            reviewer: 'Dan L.',
          },

        ],

      },

      gallerySection: {

        title: 'Children Approve too!!',

        images: [
            'https://cdn.shopify.com/s/files/1/1194/7512/t/17/assets/182134440_271951354654377_4993784289862082209_n-1634026572769_360x.jpg?v=1634026573',
            'https://cdn.shopify.com/s/files/1/1194/7512/t/17/assets/lrm_export_20180205_020554-1-1635620391312_360x.jpg?v=1635620393',
            'https://cdn.shopify.com/s/files/1/1194/7512/t/17/assets/92109154_234472381267485_8975216957493153773_n-1-1-1635620502442_360x.jpg?v=1635620502',
            'https://cdn.shopify.com/s/files/1/1194/7512/t/17/assets/35000866_2153881791525678_5296367336566030336_n-1-1635620435753_360x.jpg?v=1635620435',
            'https://cdn.shopify.com/s/files/1/1194/7512/t/17/assets/37955037_636899299998794_7108262396338110464_n-2-1635620435756_360x.jpg?v=1635620438',
            'https://cdn.shopify.com/s/files/1/1194/7512/t/17/assets/59923189_2313106595436441_7782159233660675568_n-1-1635620435758_360x.jpg?v=1635620440'
        ]

      },

      addToCartSection2: {

        leftImages: [

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/Kizing-curved-baby-and-toddler-spoon-supports-self-feeding_x593@2x.jpg?v=1619668597'
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/Kizingo-curved-toddler-spoons-make-feeding-easy_x593@2x.png?v=1619668597',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/Kizingo-toddler-spoons-for-baby-led-weaning_x593@2x.png?v=1619668597',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/curved-toddler-spoons-make-self-feeding-easy_x593@2x.png?v=1619668597',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/kizingo-curved-spoons-designed-for-self-feeding_x593@2x.png?v=1619668597',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/kizingo-curved-toddler-spoons-designed-by-doctor-moms_x593@2x.png?v=1619668597',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/Raspberry-Lagoon-Twin-Pack-01_x593@2x.png?v=1619668597',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/Mango-Wave-twin-pack-spoons-01_x593@2x.png?v=1619668597',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/lpinklpink_small-01_x593@2x.png?v=1632665009',
          },

          {
            imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/products/Raspberry-Lagoon-Twin-Pack-01_x593@2x.png?v=1644205682',
          }

        ],

        rightContent: {

          title: 'Right - Handed Curved Spoon Twin Pack (2 spoons)',

          originalPrice: '$16.99',

          price: '$14.99 and Free Shipping',

          productColors: [

            {
              id: 1,
              label: 'Wave and Mango',
            },

            {
              id: 2,
              label: 'Grapefruit and Grapefruit',
            },

            {
              id: 3,
              label: 'Raspberry and Lagoon',
            }

          ],

          submitButtonLabel: 'Add to Cart',

          shippingInfo: 'SHIPS FREE IN 1 BUSINESS DAY',

          certificationsImg: 'https://cdn.shopify.com/s/files/1/1194/7512/t/17/assets/leggo--edited-1633945144272_453x.jpg?v=1633945143',

        }

      },

      designSection: {

        imgSrc: 'https://cdn.shopify.com/s/files/1/1194/7512/t/17/assets/cofounder_kiyah-and-kids-1633874372205_500x.png?v=1633874373',

        title: 'DESIGNED BY DR. MOMS',

        subtitle: 'Kiyah Duffey PhD- Founder, Inventor, Mom',

        paragraphs: [
            'Like parents everywhere, we want our kids to be happy and healthy! And mealtime is no exception.',
            'Establishing a healthy relationship with food is critically important. As a nutrition scientist, I also understand just how hard it is to help create healthy habits; there are so many factors working against you. We wanted to change this.',
            'Our mission is to use science to design better products -- products that promote healthy habits instead of encouraging less healthy ones.',
        ]

      },

      modalSection: {

        imgSrc: 'https://do0ne7yeju3uz.cloudfront.net/uploads/form_images/100584388/side_6a0561a5945011f454d531d993171dfa.jpg',

        title: 'Would you like to save 15%?',

        subtitle: 'Enter your email, receive a code, save money. It\'s that simple!',

        clientInfo: {

          name: 'First Name',

          email: 'Email Address',

        },

        buttonLabel: 'I like saving money!',

        hasOffer: true,

      }

    };

    const loadModal = () => {
      setTimeout(() => {
        $('#modalOffer').show();
        isOpened.value = true;
      }, 5000);
    }

    return {
      content,
      isOpened,
      loadModal,
    }

  }

}

</script>

<style lang="scss">

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.kizingo-kids {
  position: relative;
  color: #230051;
  font-size: 1.6rem;
  line-height: 1.3;

  h1, h2, h3, h4, h5, h6 {
    color: rgb(61, 20, 102);
    margin: 0 0 20px;
  }

  h1 {
    font-size: 1.3em;
  }

  ul {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    list-style: disc;
    line-height: 1.6em;
    padding-left: 20px;

    li {
      margin-bottom: 10px;
    }

  }

  .awards-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    img {
      max-width: 100%;
      width: 100%;
    }

  }

  .fullscreen-image {
    max-width: 100%;
    width: 100%;
  }

}

@media only screen and (max-width: 425px) {

  .kizingo-kids {

    h1 {
      font-size: 1em;
    }

  }

}

</style>