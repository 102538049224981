<template>

  <div class="products-carousel">

    <div class="grid-container">

      <div class="content">

        <span class="title">{{ content.title }}</span>

        <div class="carousel-wrapper">

          <carousel
              :breakpoints="breakpoints"
              controlBg="#000"
          >

            <slide v-for="(product, index) in content.products"
                   :key="index"
                   class="product-block">

              <img
                  :src="product.imgSrc"
                  alt="product"
                  @click="redirectTo(product.link)"
              >

              <span
                  class="title"
                  @click="redirectTo(product.link)"
              >
                {{ product.title }}
              </span>

              <div class="rating">

                <vue3-star-ratings
                    :showControl="false"
                    :disableClick="true"
                    starSize="16"
                    starColor="#e7721b"
                    v-model="product.rating"
                />

                <span class="reviews">({{ product.reviews }})</span>

              </div>

              <span class="price">$ {{ product.price }}</span>


            </slide>

            <template #addons>

              <navigation/>

            </template>

          </carousel>

        </div>

      </div>

    </div>

  </div>

</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Slide, Navigation} from 'vue3-carousel';
import {ref} from "vue";

export default {

  name: 'ProductsCarousel',

  components: {
    Carousel,
    Slide,
    Navigation,
  },

  props: ['content'],

  setup() {

    const breakpoints = ref();

    breakpoints.value = {

      // 1024px and up
      1025: {
        itemsToShow: 6,
        snapAlign: 'center',
      },

      // 600px and up
      600: {
        itemsToShow: 3,
        snapAlign: 'start',
      },

      // 300px and up
      300: {
        itemsToShow: 1,
        snapAlign: 'start',
      }

    }

    const redirectTo  = (link) => {
      window.location.href = link;
    };

    return {
      redirectTo,
      breakpoints,
    }
  }

}

</script>

<style lang="scss" scoped>

.products-carousel {

  .content {

    .title {
      font-size: 1.5em;
      color: #6B6D76;
    }

    .carousel-wrapper {
      width: 90%;
      height: 300px;
      margin: 40px auto 0;

      button {
        background-color: red !important;
      }

      .carousel__slide {
        padding: 0 20px;
      }

      img {
        max-height: 300px;
        max-width: 100%;
      }

    }

    .product-block {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #737373;
      overflow: hidden;

      .title, img {
        cursor: pointer;
        font-size: 1em;
        line-height: normal;
      }

      .rating {
        display: flex;
        align-items: center;
        justify-content: center;

        .vue3-star-ratings__wrapper {
          margin: 0;
          padding: 10px;
        }

        .reviews {
          font-size: 14px;
        }

      }

      .price {
        margin-top: 3px;
        color: #2f84ed;
        font-weight: 700;
      }

    }

  }

}

</style>