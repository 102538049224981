<template>

  <div class="get-button" :style="{ backgroundColor: bkColor, color: color }">
    <i v-if="iconClass" :class="iconClass"/>
    <span>{{ label }}</span>
  </div>

</template>

<script>

export default {

  name: 'ScButton',

  props: ['label', 'iconClass', 'bkColor', 'color'],

  setup() {

    return {

    }

  }

}

</script>

<style lang="scss" scoped>

.get-button {
  width: 100%;
  padding: 10px;
  text-align: center;
  background-color: #d0021b;
  color: white;
  cursor: pointer;

  i {
    margin-right: 10px;
  }

  span {
    text-transform: uppercase;
  }

}

</style>