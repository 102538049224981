<template>

  <div class="addToCart-section">

    <h1 class="title">{{ content.title }}</h1>

    <p class="original-price"> &nbsp {{ content.originalPrice }} &nbsp </p>

    <div v-if="content.ratingValue && content.numberOfReviewers" class="rating">

      <vue3-star-ratings
          :showControl="false"
          :disableClick="true"
          starSize="26"
          v-model="content.ratingValue"
          @click="scrollToSection"
      />

      <span @click="scrollToSection"> {{ content.numberOfReviewers }} Reviewers </span>

    </div>

    <sc-separator color="#919da9"/>

    <p> {{ content.price }} </p>

    <sc-separator color="#919da9"/>

    <ul v-if="content.productCharacteristics">
      <li
          v-for="(item, index) in content.productCharacteristics"
          :key="index"
      >
        <span>{{ item }}</span>
      </li>
    </ul>

    <sc-separator v-if="content.productCharacteristics" color="#919da9"/>

    <div class="colors-wrapper">

      <span>CHOOSE YOUR COLORS</span>

      <div
          v-for="(color, index) in content.productColors"
          :key="index"
          class="option">

        <label>
        <input
            type="radio"
            v-model="chosenOption"
            :value="color.label"
            :checked="index === 0"
            @change="radioChangeHandler(color.id)"
        > {{ color.label }} </label>

      </div>

    </div>

    <counter class="counter-wrapper" @counter-change="counterChangeHandler($event)"/>

    <sc-separator color="#919da9"/>

    <div class="add-button" @click="addToCartHandler">
      <i class="fas fa-shopping-cart"/>
      <span>{{ content.submitButtonLabel }}</span>
    </div>

    <p class="shipping-info">{{ content.shippingInfo }}</p>

    <img class="certifications" :src="content.certificationsImg" alt="certification">

  </div>

</template>

<script>

import Counter from "@/components/Counter";
import ScSeparator from "@/components/ScSeparator";
import {scrollToSmoothly} from "@/utils/helpers";
import {ref} from "vue";

export default {

  name: 'AddToCart',

  props: ['content'],

  components: {
    Counter,
    ScSeparator,
  },

  setup(props, {emit}) {

    const counterValue = ref(1);

    const chosenOption = ref();

    chosenOption.value = props.content.productColors[0].label;

    const radioChangeHandler = (index) => {
      emit("changedRadioOption", index);
    };

    const counterChangeHandler = (value) => {
      counterValue.value = value;
    };

    const addToCartHandler = () => {
      console.log('Chosen options: ' + chosenOption.value);
      console.log('Chosen quantity: ' + counterValue.value);
    };

    const scrollToSection = () => {

      let chosenSection = document.getElementById('reviews');

      scrollToSmoothly(chosenSection.offsetTop, 600);

    };

    return {
      chosenOption,
      radioChangeHandler,
      counterChangeHandler,
      addToCartHandler,
      scrollToSection,
    }

  }

}

</script>

<style lang="scss" scoped>

.addToCart-section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;

  .title {
    margin-bottom: 0;
    text-align: left;
  }

  .original-price {
    font-weight: 700;
    text-decoration: line-through;
    color: rgb(187, 187, 187);
  }

  .rating {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;

    .vue3-star-ratings__wrapper {
      margin: 0;
      padding: 0;
    }

    span {
      margin-left: 5px;
      font-size: 13px;
      color: #2f84ed;
      text-decoration: none;
    }

  }

  ul {
    text-align: left;

    li {

      span {
        color: rgb(0, 71, 178);
      }

    }

  }

  .colors-wrapper {

    .option {
      display: flex;
      align-items: center;
      font-size: 0.7em;
      margin-top: 20px;
      margin-bottom: 15px;
      margin-left: 30px;

      input[type=radio] {
        cursor: pointer;
      }

      label {
        padding-left: 5px;
        cursor: pointer;
      }
    }

  }

  .counter-wrapper {
    align-self: center;
  }

  .add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    cursor: pointer;
    background-color: #d0021b;
    color: #fff;
    padding: 10px;
    font-size: 19px;
    line-height: 1.5;
    border-radius: 20px;
    margin: 10px 0;

    span {
      font-size: 29px;
      line-height: 1;
      text-align: center;
      letter-spacing: 1px;
      margin-left: 10px;
    }
  }

  .shipping-info {
    align-self: center;
    font-size: 0.6em;
    font-weight: 700;
  }

  .certifications {
    align-self: center;
    max-width: 100%;
    width: 100%;
    margin-top: 15px;
  }

}

</style>