<template>

  <div class="learnSection-wrapper">

    <div class="grid-container">

      <div class="learn-section">

        <h1>{{ content.heading }}</h1>

        <div class="sub-heading"> {{ content.subHeading }} </div>

        <img :src="content.imgSrc" alt="baby">

        <span class="additional-info"> {{ content.additionalInfo }} </span>

        <p
            v-for="(paragraph, index) in content.paragraphs"
            :key="index"
            class="description"
        >
          {{ paragraph }}
        </p>

      </div>

    </div>

  </div>

</template>

<script>

import ScSeparator from "@/components/ScSeparator";

export default {

  name: 'LearnToDrink',

  props: ['content'],

  components: {
    ScSeparator
  },

}

</script>

<style lang="scss" scoped>

.learnSection-wrapper {
  max-width: 100%;
  width: 100%;
  background-color: #ffcb01;
  padding: 20px 0;

  .learn-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: black;
    font-size: 2em;
    text-align: center;
    line-height: normal;

    h1 {
      text-transform: uppercase;
      font-size: 1.3em;
      font-weight: 700;
      margin-bottom: 0;
      color: #d0021b;
    }

    .sub-heading {
      margin: 20px 0;
    }

    img {
      max-width: 100%;
      width: 733px;
    }

    .additional-info {
      margin: 10px 0 20px;
    }

    .description {
      margin-bottom: 30px;
      color: #d0021b;
    }

  }

}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 425px) {

  .learnSection-wrapper {

    .learn-section {
      font-size: 1.6em;

      .description {
        text-align: left;
      }

    }

  }

}

</style>