<template>

  <div class="insurances-section">

    <div class="grid-container">

      <div class="content">

        <div class="left-side">

          <img :src="content.imgSrc" alt="img">

        </div>

        <div class="right-side">

          <h1>{{ content.title }}</h1>

          <ul>
            <li
                v-for="(insurance, index) in content.insurances"
                :key="index"
            >
              {{ insurance }}
            </li>
          </ul>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'Insurances',

  props: ['content'],

}

</script>

<style lang="scss" scoped>

.insurances-section {
  padding: 50px 0;
  background-color: rgba(245,166,35,.25);

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    background-color: rgba(245,166,35,.25);
    padding: 10px 0;

    .left-side {
      flex-grow: 1;
      width: 50%;

      img {
        max-width: 100%;
        width: 100%;
      }

    }

    .right-side {
      flex-grow: 1;
      width: 50%;

      h1 {
        font-size: 1em;
      }

      ul {
        li {
          font-size: 0.8em;
          margin-bottom: 0;
        }
      }

    }

  }

}

@media only screen and (max-width: 769px) {

  .insurances-section {

    .content {

      .right-side {
        line-height: 1.4em;

        h1 {
          font-size: 1.2em;
        }

        ul {
          font-size: 0.8em;
          line-height: 1.4em;

        }

      }

    }

  }

}

@media only screen and (max-width: 426px) {

  .insurances-section {

    .content {
      flex-direction: column;
      width: fit-content;
      margin: auto;

      .left-side {
        width: 100%;
      }

      .right-side {
        width: 100%;
        padding: 20px;

        h1 {
          font-size: 1em;
          text-align: center;
        }

      }

    }

  }

}

</style>