<template>

  <div class="addToCart-section">

    <h1 class="title">{{ content.title }}</h1>

    <ul>
      <li
          v-for="(item, index) in content.characteristics"
          :key="index"
      >
        {{ item }}
      </li>
    </ul>

    <h1 class="additional-info">{{ content.additionalInfo }}</h1>

    <img class="product-image" :src="content.productImg" alt="product">

    <sc-separator color="#919da9"/>

    <div class="prices-wrapper">

      <p class="original-price"> &nbsp {{ content.originalPrice }} &nbsp </p>

      <p class="price"> &nbsp {{ content.price }} &nbsp </p>

    </div>

    <sc-separator color="#919da9"/>

    <h1 class="pack-content-title">{{ content.packContentTitle }}</h1>

    <ul class="product-content">
      <li
          v-for="(item, index) in content.packageContent"
          :key="index"
      >
        {{ item }}
      </li>
    </ul>

    <sc-separator color="#919da9"/>

    <counter @counter-change="counterChangeHandler($event)"/>

    <div class="add-button" @click="addToCartHandler">
      <i class="fas fa-shopping-cart"/>
      <span>{{ content.submitButtonLabel }}</span>
    </div>

    <img class="certifications" :src="content.certificationsImg" alt="certification">

  </div>

</template>

<script>

import {ref} from "vue";
import Counter from '@/components/Counter.vue';
import ScSeparator from "@/components/ScSeparator";

export default {

  name: 'AddToCart2',

  props: ['content'],

  components: {
    Counter,
    ScSeparator,
  },

  setup() {

    const counterValue = ref(1)

    const counterChangeHandler = (value) => {
      counterValue.value = value;
    }

    const addToCartHandler = () => {
      console.log('Chosen quantity: ' + counterValue.value);
    }

    return {
      counterChangeHandler,
      addToCartHandler,
      counterValue,
    }

  }

}

</script>

<style lang="scss" scoped>

.addToCart-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;

  .title {
    color: rgb(136, 136, 136);
    text-align: center;
    font-size: 1.5em;
  }

  .additional-info {
    text-align: center;
    font-size: 1.5em;
    line-height: 1.2;
  }

  .product-image {
    border-radius: 25px;
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;
  }

  .prices-wrapper {
    display: flex;
    padding: 5px 0;
    align-self: flex-start;

    .original-price {
      font-weight: 700;
      text-decoration: line-through;
      color: rgb(187, 187, 187);
    }

    .price {
      font-weight: 700;
      color: rgb(68, 68, 68);
    }

  }

  .pack-content-title {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 1.4em;
    align-self: flex-start;
  }

  ul {
    align-self: flex-start;
  }

  ul.product-content {

    li {
      font-size: 0.8em;
    }
  }

  .counter-wrapper {
    margin: 10px 0;
  }

  .add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    cursor: pointer;
    background-color: #d0021b;
    color: #fff;
    padding: 10px;
    font-size: 19px;
    line-height: 1.5;
    font-weight: 700;
    border-radius: 20px;
    margin: 10px 0;

    span {
      font-size: 29px;
      line-height: 1;
      font-weight: 700;
      text-align: center;
      letter-spacing: -1px;
      margin-left: 10px;
    }
  }

  .shipping-info {
    font-size: 0.6em;
    font-weight: 700;
  }

  .certifications {
    margin-top: 15px;
  }

  img {
    max-width: 100%;
  }

}

@media only screen and (max-width: 426px) {

  .addToCart-section {

    .title {
      font-size: 1.2em;
    }

    .additional-info {
      font-size: 1em;
      background-color: rgba(155,155,155,.1);
    }

    .pack-content-title {
      font-size: 1em;
    }

    ul {

      li {
        font-size: 0.8em;
        line-height: 1.3;
      }
    }

  }

}

</style>