<template>

  <div class="addToCart-section">

    <h1 v-if="content.hasOwnProperty('title')" class="title">{{ content.title }}</h1>

    <span v-if="content.hasOwnProperty('subTitle')" class="subTitle">{{ content.subTitle }}</span>

    <div v-if="content.hasOwnProperty('ratingValue')" class="rating">

      <vue3-star-ratings
          :showControl="false"
          :disableClick="true"
          starSize="16"
          v-model="content.ratingValue"
      />

      <span> {{ content.numberOfReviewers }} Reviewers</span>

    </div>

    <span v-if="content.hasOwnProperty('freeShipping')" class="shipping"> {{ content.freeShipping }} </span>

    <div v-if="content.hasOwnProperty('radioButtonOptions')" class="radioButtons-wrapper">

      <div
          v-for="(option, index) in content.radioButtonOptions"
          :key="index"
          class="option">
        <input
            type="radio"
            v-model="chosenOption"
            :value="option"
            :checked="index === 0"
            @change="radioChangeHandler(index)"
        ><span> {{ option }} </span>
      </div>

    </div>

    <counter class="counter-wrapper" @counter-change="counterChangeHandler($event)"/>

    <div v-if="content.hasOwnProperty('addToCartLabel')" class="add-button" @click="addToCartHandler">
      <i class="fas fa-shopping-cart"/>
      <span>{{ content.addToCartLabel }}</span>
    </div>

    <img v-if="content.hasOwnProperty('paymentBanner')" :src="content.paymentBanner" alt="payment" class="payment-methods">

    <span v-if="content.hasOwnProperty('shippingInfo')" class="shippingInfo">{{ content.shippingInfo }}</span>

    <img v-if="content.hasOwnProperty('certifications')" :src="content.certifications" alt="certifications" class="certifications">

  </div>

</template>

<script>

import {ref} from "vue";
import Counter from '@/components/Counter.vue';

export default {

  name: 'AddToCart',

  props: ['content'],

  components: {
    Counter,
  },

  setup(props, {emit}) {

    const counterValue = ref(1);

    const chosenOption = ref();

    chosenOption.value = props.content.radioButtonOptions[0];

    const radioChangeHandler = (index) => {
      emit("changedRadioOption", index);
    };

    const counterChangeHandler = (value) => {
      counterValue.value = value;
    }

    const addToCartHandler = () => {
      console.log('Chosen options: ' + chosenOption.value);
      console.log('Chosen quantity: ' + counterValue.value);
    }

    return {
      chosenOption,
      radioChangeHandler,
      counterChangeHandler,
      addToCartHandler,

    }

  }

}

</script>

<style lang="scss" scoped>

.addToCart-section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  .title {
    text-align: center;
    color: #d0021b;
    font-size: 2em;
    width: 100%;
  }

  .subTitle {
    text-align: center;
    color: #d0021b;
    font-size: 1em;
    width: 100%;
  }

  .rating {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;

    .vue3-star-ratings__wrapper {
      margin: 0;
      padding: 0;
    }

    span {
      margin-left: 5px;
      font-size: 13px;
      color: #2f84ed;
      text-decoration: none;
      cursor: pointer;
    }

  }

  .shipping {
    font-size: 17px;
    color: rgb(136, 136, 136);
    font-weight: 400;
    margin-bottom: 15px;
  }

  .radioButtons-wrapper {
    .option {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      span {
        margin-left: 5px;
      }
    }
  }

  .counter-wrapper {
    margin-bottom: 10px;
  }

  .add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    background-color: #ef3340;
    padding: 20px;
    text-transform: uppercase;
    border-radius: 100px;
    cursor: pointer;
    color: #000;

    span {
      font-size: 29px;
      line-height: 1;
      font-weight: 700;
      text-align: center;
      letter-spacing: -1px;
      margin-left: 10px;
    }
  }

  .payment-methods {
    max-width: 100%;
    margin-bottom: 25px;
  }

  .shippingInfo {
    width: 100%;
    text-align: center;
    color: #d0021b;
  }

  .certifications {
    max-width: 100%;
  }

}

@media only screen and (max-width: 425px) {

  .addToCart-section {

    .counter-wrapper {
      align-self: center;
    }

    .add-button {
      padding: 10px;
      color: #fff;

      span {
        font-size: 20px;
      }
    }

    .payment-methods {
      display: none;
    }

  }

}

</style>