<template>

  <div class="carousel">
    <slot :currentSlide="currentSlide"/>

<!--    Navigation   -->

    <div
        class="navigate"
        v-if="navigationEnabled"
    >

      <div class="toggle-page left">
        <i
            v-if="currentSlide > 1"
            class="fas fa-chevron-left"
            @click="prevSlide"
        />
      </div>

      <div class="toggle-page right">
        <i
            v-if="currentSlide < slideCount"
            class="fas fa-chevron-right"
            @click="nextSlide"
        />
      </div>

    </div>

    <!--    Pagination   -->

    <div
        class="pagination"
        v-if="paginationEnabled"
    >

      <span
          v-for="(slide, index) in slideCount"
          :key="index"
          :class="{ active: index + 1 === currentSlide}"
          @click="goToSlide(index)"
      />

    </div>

  </div>

</template>

<script>

import {ref, watch} from "vue";

export default {

  name: 'Carousel',

  props: ['startAutoPlay', 'timeout', 'navigation', 'pagination', 'slideCount', 'activeSlide'],

  setup(props) {

    const currentSlide = ref(1);

    const autoPlayEnabled = ref(props.startAutoPlay === undefined ? true : props.startAutoPlay);

    const timeoutDuration = ref(props.timeout === undefined ? 5000 : props.timeout);

    const navigationEnabled = ref(props.navigation === undefined ? true : props.navigation);

    const paginationEnabled = ref(props.pagination === undefined ? true : props.pagination);

    watch(() => props.activeSlide, (activeIndex) => {
      currentSlide.value = activeIndex;
    });

    // next slide
    const nextSlide = () => {
      if (currentSlide.value === props.slideCount) {
        currentSlide.value = 1;
        return;
      }
      currentSlide.value += 1;
    };

    // prev slide
    const prevSlide = () => {
      if (currentSlide.value === 1) {
        currentSlide.value = 1;
        return;
      }
      currentSlide.value -= 1;
    };

    const goToSlide = (index) => {
      currentSlide.value = index + 1;
    };

    // autoplay
    const autoPlay = () => {
      setInterval(() => {
        nextSlide();
      }, timeoutDuration.value);
    };

    if (autoPlayEnabled.value) {
      autoPlay();
    }

    return {
      currentSlide,
      navigationEnabled,
      paginationEnabled,
      nextSlide,
      prevSlide,
      goToSlide,
    }

  }

}

</script>

<style lang="scss" scoped>

.navigate {
  padding: 0 16px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .toggle-page {
    display: flex;
    flex: 1;
  }

  .right {
    justify-content: flex-end;
  }

  i {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: rgba(0,0,0,.4);
    color: #fff;
    font-size: 0.7em;

    &:hover {
      background-color: rgba(0,0,0,.8);
    }
  }

}

.pagination {
  position: absolute;
  bottom: -35px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  span {
    cursor: pointer;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #d9d9d9;
    opacity: 0.5;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

    &.active {
      background-color: #0d0d0d;
    }
  }

}

</style>