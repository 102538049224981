<template>

  <div class="promotion-section">

    <div class="grid-container">

      <div class="content">
        <h1 class="title" v-if="content.hasOwnProperty('title')"> {{content.title}}</h1>
        <h1 class="red-title" v-if="content.hasOwnProperty('redText')"> {{content.redText}}</h1>
        <span class="thin-text" v-if="content.hasOwnProperty('thinText')"> {{content.thinText}}</span>
        <span class="bold-text" v-if="content.hasOwnProperty('boldText')"> {{content.boldText}}</span>
      </div>

    </div>

  </div>

</template>

<script>

export default {

  name: 'Promotion',

  props: ['content'],

  setup() {

    return {

    }

  }

}

</script>

<style lang="scss" scoped>

.promotion-section {
  background-color: #ecf1d8;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    text-align: center;

    .red-title {
      color: rgb(230, 0, 0);
    }

    .thin-text {
      font-size: 0.7em;
    }

    .bold-text {
      font-weight: bold;
    }
  }
}

</style>